@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27, 12);
}
.lps_sec {
  position: relative;
  font-size: calc-fz(18);
  &:nth-child(odd) {
    background-color: var(--clr-bg-01);
    .txt_slide--item {
      color: var(--clr-wht);
    }
    .bg-wht {
      background-color: var(--clr-wht);
    }
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      border-bottom: 1px solid var(--clr-bg-02);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &:nth-child(even) {
    background-color: var(--clr-wht);
  }
}
.page-entry  .lps_sec:nth-child(odd)::after {
  display: none;
}

.lps_sec.bg-blue {
  background-color: var(--clr-primary-default);
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

.sec_blue_bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: clip;
  background-color: var(--clr-primary-default);
}
.sec_blue_bg--item {
  border-radius: 100%;
  height: rem(600);
  width: rem(600);
  opacity: 0.4;
  background: var(--clr-primary-light);
  filter: blur(150px);
}

.sec_blue_bg--item.left {
  position: absolute;
  top: -10%;
  left: -10%;
}
.sec_blue_bg--item.right {
  position: absolute;
  bottom: -10%;
  right: -10%;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
  &.mgn-btm0 {
    margin-bottom: 0;
  }
}
.lps_parts:has(.inner-lg:has(.img_text--img)) {
  margin-bottom: 0;
}

.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}
.page:not(.page-simple) {
  .lps_sec--wrap {
    @include mq(med, min, ps) {
      position: relative;
      width: 90%;
      max-width: rem($inner);
      @include auto-margin;
      display: grid;
      grid-template-columns: auto rem(920);
      gap: rem(80);
      align-items: flex-start;
      .inner {
        width: 100%;
        max-width: 100%;
      }
      & > *:first-child {
        position: sticky;
        top: rem(164);
        margin-bottom: 0 !important;
      }
      & > *:only-child {
        grid-column: 1/3;
      }
    }
    @include mq(med) {
      .lps_sec--sticky:not(:last-child) {
        margin-bottom: sprem(52);
      }
    }
    &:not(:last-child) {
      margin-bottom: rem(80);
      @include mq(sp) {
        margin-bottom: sprem(40);
      }
    }
  }

  .lps_sec--wrap .ttl-01 {
    @include mq(med, min, ps) {
      .subtitle {
        margin-left: 0;
      }
      text-align: left;
      &::after {
        left: 0;
        translate: 0;
      }
    }
  }
}

.lps_sec--wrap .ttl-01.txt-ctr .subtitle::after {
  display: none;
  @include mq(sp) {
    display: block;
  }
}