@use "../abstracts" as *;

/* cta_tel
**************************************** */
.cta_tel a {
  @include center-flex;
  flex-direction: column;
  padding: rem(12);
  line-height: var(--line-height-hdr);
  border-radius: rem(4);
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: calc-fz(18);
}
.cta_tel .num {
  font-size: calc-fz(28);
}
.cta_tel .hours {
  font-size: calc-fz(13);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
  border-radius: rem(4);
  background: var(--color-gradation-gradation03);
}
.cta_contact .micro_copy {
  position: absolute;
  top: calc(-1em - rem(10));
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: rem(13);
  color: var(--clr-secondary-default);
  text-align: center;
  line-height: 1;
  &::before,
  &::after {
    content: '';
    width: rem(2);
    height: rem(16);
    background-color: var(--clr-secondary-default);
  }
  &::before{
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}
