@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact {
  .cta_tel a {
    padding: rem(24) rem(40);
    width: fit-content;
    @include auto-margin;
    @include mq(sp) {
      display: block;
      padding: sprem(16);
      text-align: center;
    }
  }
}
.contac_section {
  background: var(--clr-bg-01);
  &.lps_sec:nth-child(even) {
    background: var(--clr-bg-01);
  }
}

.page-contact-simple 
.lps_sec:nth-child(odd) {
  border-bottom: 0;

}

.page-entry .contac_section {
  padding-top: 0;
}