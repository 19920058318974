@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: rem(14) rem(40) rem(14) rem(32);
  padding-right: rem(72);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  text-decoration: none;
  
  @include mq(sp) {
    min-width: 100%;
  }
  &:hover {
    background-color: var(--clr-primary-dark);
  }

  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.entry_btn a {
padding-inline: rem(14);
text-align: center;
justify-content: center;
}
.btn.btn-ctr a {
  @include auto-margin;
}

.linkarrow {
  width: rem(28);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

.btn-arrow2 a {
  color: var(--clr-primary-default);

}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}

.btn2 {
  display: flex;
  column-gap: rem(24);
  align-items: center;
}
.btn2.circle-arrow  .arrow {
  width: rem(40);
  height: rem(40);
  .linkarrow {
    width: rem(17);
  }
}

.btn2--txt {
  text-align: left;
}

.btn_wrap_type2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: rem(56);
  place-items: end normal;
  @include mq(sp) {
  row-gap: sprem(20);
  grid-template-columns: 100%;
  }
}
.btn.type2 {
  height: fit-content;
  background-color: transparent;
  border-bottom: 1px solid var(--clr-primary-default);
  margin-bottom: 0;
}
.btn.type2 a {
  padding-inline: 0;
  color: var(--clr-primary-default);
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: rem(10);
  text-align: left;
}

.btn.type2 .icon-svg {
  position: relative;
  top: initial;
  transform: none;
  right: initial;
}