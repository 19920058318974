@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  padding-block: rem(64);
  background: var(--clr-wht);

  @include mq(sp) {
    overflow: auto;
    padding-block: sprem(32);
    background: var(--clr-wht);
    border-bottom: var(--clr-bg-02) 1px solid;
  }
}
.anchor_link--list {
  row-gap: rem(16);
  @include mq(sp) {
    flex-direction: column;
    flex-wrap: inherit;
    white-space: nowrap;
    width: 100%;
    margin-inline: calc(50% - 50vw);
    padding-inline: 7%;
    overflow: auto;
    row-gap: 0;
  }
}
.anchor_link--list li {
  @include mq(sp) {
    width: 100%!important;
    &:last-child {
      margin-right: sprem(-10);
    }
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: left;
  padding: rem(24) rem(40);
  line-height: var(--line-height-hdr);
  border-bottom: 1px solid var(--clr-bg-02);
  line-height: 1;
  &:hover {
    background-color: var(--clr-bg-01);
  }
  @include mq(sp) {
    padding: sprem(14) sprem(24) sprem(14) sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(24);
    translate:  0 -50%;
    @include rect(18, 24);
    transition: var(--transit-default);
    background-image: url('../images/common/under_arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include mq(sp) {
      right: sprem(10);
    }
  }
  &:hover {
    color: var(--clr-primary-default);
  }
  @include mq(sp) {
    width: 100%;
  }
}

.anchor_link--list li:last-of-type a {
  @include mq(sp) {
   border-bottom: 0;
  }
}