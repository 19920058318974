@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */


.entery_section_wrap {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: 100%;
    >* {
      grid-column: 1;
      grid-row: 1;
    }
  }
}

.joinus_box_wrap {
  height: 100%;

}

.joinus_box {
  width: 100%;
  @include mq(sp, min, ps) {
    height: 100vh;
    top: rem(80);
    position: sticky;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    color: var(--clr-wht);

    >* {
      grid-column: 1;
      grid-row: 1;
    }
  }
}

.joinus_box--img {
  width: 100%;
}
.home_joinus_box {
  text-align: center;
  overflow: hidden;
  line-height: 1;
  display: block;
  height: rem(200);
  @include mq(sp) {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    translate: -50% -100%;
    left: 50%;
    z-index: 1;
  }
}


.home_joinus_box .joinus_box--txt {
  height: 100%;
  display: block;
  translate: 0;
}

.joinus_box--txt {
  height: calc(rem(750));
  font-size: calc-fz(200);
  color: rgba(#fff, 0.9);
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 0.68;
  place-items: flex-end normal;
  place-content: end normal;
  translate: 0 0.1em;
  font-family: var(--font-en);
  @include mq(sp) {
    font-size: sprem(80);
  }
}

.entery_section_head {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(sp) {
    padding-top: 0;
  }
}


.entery_section {
  position: relative;
  z-index: 1;

}

.entery_section--inner {
  background: rgba(255, 255, 255, 0.9);
  @include mq(sp) {
    padding-top: 0;
  }
}

.entery_section--list {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  column-gap: rem(40);
  @include mq(sp) {
    flex-direction: column;
    row-gap: rem(40);
  }
}

.entery_section--item {
  border-radius: rem(4);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: rem(40);
  width: rem(604);
  height: rem(280);
  transition: var(--transit-default);
  position: relative;
  max-width: 90%;
  @include mq(sp) {
    margin-inline: auto;
    height: sprem(140);
  } 

  &:hover {
    background: var(--clr-wht);
  }

  &::before {
    content: "";
    display: block;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background: var(--color-gradation-gradation02);
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: rem(8);
    z-index: -1;
  }

  .font-en,
  .font-jp,
  .icon {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
}

.entery_section--item {
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .font-jp {
    line-height: 1.4;
  }

  .font-em {
    line-height: 1.6;
  }

  &.career {
    &::before {
      background: var(--color-gradation-gradation03);
    }
  }

  .sub_img {
    position: absolute;
    bottom: rem(-80);
    pointer-events: none;
    @include mq(sp) {
      width: 20%;
      bottom: 0;
    }
  }

  &.graduate .sub_img {
    left: rem(-216);

    @include mq(sp) {
      left: 0;
      bottom: initial;
      top: sprem(-40);
    }
  }

  &.career .sub_img {
    right: rem(-216);

    @include mq(sp) {
      right: 0;
      bottom: sprem(-40);
    }
  }

  &:hover {

    .font-en,
    .font-jp,
    .icon {
      -webkit-filter: brightness(1) invert(0);
      filter: brightness(1) invert(0);
    }
  }
}

.ipad {
.entery_section--item.career .sub_img {
 right: 0;
 width: 50%;
}
.entery_section--item.graduate .sub_img {
 left: 0;
 width: 50%;
}
.home_data_acc {
 right: 0;
}
}

.footer {
  background-color: var(--clr-primary-dark);
  position: relative;
  z-index: 1;
}

/* footer_top
********************************************** */
.footer_top .inner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: rem(40);
  grid-template-areas:
    "logo fnav"
    "address fnav"
    "sns copy";
  color: var(--clr-wht);

  @include mq(sp) {
    row-gap: sprem(20);
    grid-template-columns: 100%;
    grid-template-areas:
      "logo"
      "address"
      "fnav"
      "cta"
      "sns"
      "privacy"
      "copy";
  }
}
.gnav--cta_footwrap {
  grid-area: cta;
  .gnav--cta {
    margin-bottom: 0;
  }
}
.gnav-privacy {
  grid-area: privacy;
}

.footer--logo {
  grid-area: logo;
  width: rem(130);
}

.footer--address {
  grid-area: address;
}

/* ---fnav --- */
.fnav {
  grid-area: fnav;
}

.fnav--menu {
  column-gap: clamp(rem(20), vw(100), rem(100));
  justify-content: flex-end;
}

.fnav--menu a {
  display: block;
  padding: 0.5em;
  color: var(--clr-wht);

  &:hover {
    opacity: 0.7;
  }

  @include mq(sp) {
    position: relative;
    padding: 0.5em;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.5em;
      margin: auto;
      @include sprect(6);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}

.fnav--menu a:where([href="javascript:void(0);"]) {
  @include mq(sp) {
    &::before {
      display: none;
    }
  }
}

.fnav--menu>.menu-item:not(.no_level)>a {
  color: var(--clr-primary-light);
  font-size: calc-fz(18);
}

.fnav--menu .menu-item:not(.no_level) .sub-menu {
  font-size: calc-fz(16);
  line-height: 1;
}

/* ---sns_area --- */
.sns_area {
  grid-area: sns;
  height: fit-content;
  column-gap: rem(10);
  @include mq(med) {
  ul {
    justify-content: center;
  }
  }
}



.sns_area a {
  display: grid;
  place-items: center;
  background: var(--clr-wht);
  border-radius: 100%;
  color: #ee3147;
  @include rect(40);

  &:hover {
    opacity: 0.7;
  }
}

.sns_area svg {
  fill: #d20f1e;
  @include rect(24);

  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */

.footer .copyright {
  @include mq(sp) {
  justify-content: center;
  text-align: center;
  }
}

.footer_btm {
  padding: rem(8) 0;
}

.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    &>.inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}

.privacy a:hover {
  opacity: 0.7;
}

.copyright {
  height: fit-content;
  grid-area: copy;
  gap: 1em;
  justify-content: flex-end;

  @include mq(sp) {
    gap: 0.5em;
  }
}

.pbl a,
.pbl img {
  display: block;
  @include mq(sp) {
    margin-inline: auto;
  }
}

.pbl a {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}

.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    &>.inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.footer--cta {
  @include mq(sp, min, ps) {
    height: fit-content;
    width: fit-content;
    margin-inline: auto;

    .cta_contact {
      margin-bottom: 0;

      a {
        height: 100%;
      }
    }
  }
}

.footer--cta .cta_tel a {
  margin-inline: auto;
  width: 100%;

  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}

// お問い合わせシンプル「以外」のとき
/* footer-01
  *************************************************** */
.footer-01 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "logo sns_area"
        "fnav copyright";
    }
  }

  .footer--logo {
    @include mq(sp, min, ps) {
      grid-area: logo;
      border-bottom: 1px solid var(--clr-mono-06);
    }

    @include mq(sp) {
      text-align: center;
    }
  }

  .fnav {
    font-size: calc-fz(14);

    @include mq(sp, min, ps) {
      grid-area: fnav;
    }

    @include mq(sp) {
      margin-block: sprem(24);
      padding-block: sprem(14);
      border-block: 1px solid var(--clr-mono-06);
    }
  }

  .fnav--menu {
    line-height: 1.5;

    @include mq(sp, min, ps) {
      margin-inline: -1em;
    }
  }

  .fnav--menu a {
    @include mq(sp) {
      margin-inline: -0.5em;
    }
  }

  .menu-item-has-children,
  .fnav--menu .sub-menu {
    @include mq(sp, min, ps) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .fnav--menu .sub-menu {
    @include mq(sp) {
      margin-left: 1em;
    }
  }

  .sns_area {
    @include mq(sp, min, ps) {
      grid-area: sns_area;
      border-bottom: 1px solid var(--clr-mono-06);
    }
  }

  .sns_area ul {
    justify-content: flex-end;

    @include mq(sp) {
      justify-content: center;
    }
  }

  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
      justify-content: flex-end;
    }

    @include mq(sp) {
      justify-content: center;
      flex-direction: row;
      margin-top: sprem(14);
    }
  }

  .copyright small {
    font-size: calc-fz(12);
  }

  .fnav,
  .copyright {
    @include mq(sp, min, ps) {
      padding-top: rem(64);
    }
  }

  .footer--logo,
  .sns_area {
    @include mq(sp, min, ps) {
      padding-bottom: rem(64);
    }
  }

  .footer--cta,
  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    margin-top: rem(24);

    @include mq(sp, min, ps) {
      padding-top: rem(24);
      border-top: 1px solid var(--clr-mono-06);
    }
  }
}

/* footer-02
  *************************************************** */
.footer-02 {
  .footer--logo {
    margin-bottom: rem(64);
    text-align: center;
  }

  .footer--address {
    margin-bottom: rem(48);
    font-size: calc-fz(15);
    text-align: center;

    @include mq(sp) {
      margin-bottom: rem(14);
    }
  }

  .fnav {
    margin-bottom: rem(40);

    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }

  .footer--cta {
    margin-bottom: rem(48);
  }

  .footer--cta a {
    margin-inline: auto;
    text-align: center;
  }

  .fnav--menu {
    justify-content: center;
    line-height: 1;

    @include mq(sp, min, ps) {
      gap: 1em 0;
    }
  }

  .fnav--menu>li:not(:last-child) {
    @include mq(sp, min, ps) {
      border-right: 1px solid;
    }
  }

  .fnav--menu a {
    padding: 1em;

    @include mq(sp, min, ps) {
      margin-block: -1em;
    }

    @include mq(sp) {
      margin-inline: -1em;
    }
  }

  .sns_area ul {
    justify-content: center;
  }

  .copyright {
    justify-content: center;
    margin-top: sprem(14);
  }
}

/* footer-03
  *************************************************** */
.footer-03 {
  .inner {
    display: grid;

    @include mq(sp, min, ps) {
      grid-template-columns: rem(310) rem(310) 1fr;
      grid-template-areas:
        "logo sns_area map"
        "address address map"
        "cta_cmt cta_cmt map"
        "cta cta map"
        "fnav fnav map"
        "copyright copyright map";
    }

    @include mq(sp) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-areas:
        "logo"
        "cta_cmt"
        "cta"
        "fnav"
        "address"
        "map"
        "sns_area"
        "copyright";
    }
  }

  .footer--logo {
    margin-bottom: rem(24);
    grid-area: logo;

    @include mq(sp) {
      text-align: center;
    }
  }

  .footer--cta {
    margin-bottom: rem(24);
    grid-area: cta;

    @include mq(sp) {
      align-items: center;
      justify-content: center;
    }
  }

  .fnav {
    margin-bottom: rem(24);
    grid-area: fnav;
  }

  .fnav--menu {
    @include mq(sp, min, ps) {
      margin-inline: -1em;
      gap: rem(16);
    }
  }

  .cta {
    grid-area: cta;
  }

  .copyright {
    grid-area: copyright;
  }

  .sns_area {
    grid-area: sns_area;

    @include mq(sp) {
      margin-bottom: sprem(32);
      margin-top: sprem(14);
    }
  }

  .sns_area ul {
    justify-content: flex-end;

    @include mq(sp) {
      justify-content: center;
    }
  }

  .copyright {
    justify-content: flex-start;

  }

  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    opacity: 0.5;
    grid-area: address;

    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }

  .cta_comment {
    grid-area: cta_cmt;
  }

  .fmap {
    grid-area: map;

    @include mq(sp, min, ps) {
      margin-left: auto;
    }

    iframe {
      @include mq(sp) {
        height: sprem(240);
        width: 100%;
      }
    }
  }

  .fnav--menu a {
    display: block;
    padding: 0.5em 1em;

    &:hover {
      opacity: 0.7;
    }

    @include mq(sp, min, ps) {
      position: relative;
      padding: 0.5em 0.5em 0.5em 1.5em;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        @include sprect(6);
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg);
      }
    }
  }
}