@use "sass:map";
@use "sass:math";
// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 145;
$header-hgt-sp: 64;
$header-hgt-fx: 128;
$list-dot: 10; // リストのドットサイズ
$list-gap: 16; // リストの余白
$list-start: $list-dot + $list-gap;
$check: 10; // チェックリストのチェックのサイズ
$check-dot: 24; // チェックリストのドットサイズ
$check-em: calc($check / $fz-pc) * 1em;
$check-dot-em: calc($check-dot / $fz-pc) * 1em;
$check-start-em: calc($check-em + $check-dot-em);
$flow-dot: 24; // 流れのドットサイズ
$flow-num-dot: 64; // 流れ（連番）のドットサイズ
$flow-gap: 32; // 流れの余白
$flow-bd: 3; // 流れのボーダーサイズ
$flow-bd-gap: 6; // 流れのボーダー余白
$flow-start: $flow-num-dot + $flow-gap;
$flow-dot-sp: 16; // 流れのドットサイズ（SP）
$flow-num-dot-sp: 40; // 流れ（連番）のドットサイズ（SP）
$flow-num-dot-sp_2: 54; 
$flow-gap-sp: 16; // 流れの余白（SP）
$flow-bd-sp: 2; // 流れのボーダーサイズ（SP）
$flow-bd-gap-sp: 4; // 流れのボーダー余白
$flow-start-sp: $flow-num-dot-sp + $flow-gap-sp;


// カラー管理
$colors: (
  clr-primary-default: #00008B,
  clr-primary-dark: #080059,
  clr-primary-light: #2E9AD4,

  clr-secondary-default: #E60012,
  clr-secondary-dark: #C22323,
  clr-secondary-light: #F25353,

  clr-bg-01: #F4F2F5,
  clr-bg-02: #9F97B4,

  clr-sub-01: #51AAA6,
  clr-sub-02: #A8BF71,
  clr-sub-03: #D7A761,
  clr-sub-04: #CF727C,
  clr-sub-05: #A2689B,
  clr-sub-06: #6790B7,

  clr-mono-01: #F2F2F2,
  clr-mono-02: #DFDFE0,
  clr-mono-03: #BABDC4,
  clr-mono-04: #9599A3,
  clr-mono-05: #646B7A,
  clr-mono-06: #333841,
  illust-blue04: #DCF5F8,

  clr-text-primary: #171523,
  clr-text-secondary: #080059,
  clr-text-placeholder: #9195A9,
  clr-text-light: #F7F7F7,

  clr-wht: #fff,
  clr-blk: #000,
  color-gradation-gradation01 : linear-gradient(90deg, #0220A4 0%, #97037A 45.5%, #E6002E 100%),

    color-gradation-gradation02 : linear-gradient(102deg, #2E9AD4 6.37%, #176DDD 93.01%),
  color-gradation-gradation03 : linear-gradient(103deg, #EE3147 0.27%, #D20F1E 99.73%),
);


    // font-weight: 100; /* thin */
    // font-weight: 200; /* extra light */
    // font-weight: 300; /* light */
    // font-weight: 400; /* normal */
    // font-weight: 500; /* medium */
    // font-weight: 600; /* semi bold */
    // font-weight: 700; /* bold */
    // font-weight: 800; /* extra bold */
    // font-weight: 900; /* black */


// CSS変数
:root {
  // typography
  --ltr-space-default: 0.08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-default: "あおとゴシック M",YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif; // webフォントを使わない場合
  --font-primary: "あおとゴシック M", sans-serif; // 本文。txt-md-jp
  --font-secondary: "あおとゴシック DB", "Aoto Gothic DemiBold", sans-serif; // ↑で足りないとき
  --font-jp: "あおとゴシック DB", "Aoto Gothic DemiBold", sans-serif; // title周りで使うfont。足りなければ増やす
  --font-en: "Plus Jakarta Sans","あおとゴシック DB", "Aoto Gothic DemiBold", sans-serif;
  // font-family: "Plus Jakarta Sans", sans-serif;
  // font-optical-sizing: auto;
  // font-weight: <weight>;
  // font-style: normal;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key} : #{$color};
  }

  // animation
  --transit-default: all .4s ease-out;


}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);

$cell: calc(100% / 38);
$cell_row: calc(100% / 18);

$inner: 1568;

$inner_half: math.div($inner, 2);