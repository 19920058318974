@use "../abstracts" as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.related_page_wrap {
  padding-inline: rem(24);
  background: var(--clr-bg-01);
  @include mq(sp) {
    padding-inline: 5%;
    padding-bottom: sprem(60);
  }
}
.related_page--list {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: grid;
  column-gap: 1px;
  row-gap: 1px;


  grid-template-columns: repeat(auto-fit, minmax(calc((100% - 2px) / 3), 1fr));
  @include mq(sp, min, ps) {
    background-color: var(--clr-bg-02);
  }

  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: sprem(40);
    aspect-ratio: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    .grid {
      height: fit-content;
      margin-top: auto;
      background: rgb(255 255 255 / 0.5);
      backdrop-filter: blur(8px);
      padding-block: sprem(10);
    }
  }
 

}


.related_page--list li {
  &:nth-child(9n-1) a::before,
  &:nth-child(9n-3) a::before,
  &:nth-child(9n-8) a::before {
    filter: brightness(1.1);
  }
  &:nth-child(9n-2) a::before,
  &:nth-child(9n-4) a::before,
  &:nth-child(9n-6) a::before {
    //  filter: brightness(0.9);
  }
}
.related_page--list a {
  width: 100%;
  position: relative;
  padding: 0;
  line-height: var(--line-height-hdr);
  color: var(--clr-primary-default);
  text-align: center;
  .grid {
    position: relative;
    z-index: 1;
    height: rem(424);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    place-items: center;
    justify-content: center;

  }
  @include mq(sp, min, ps) {
    height: rem(280);
  }
  @include mq(sp) {
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    > * {
      grid-column: 1;
      grid-row: 1;
    }
    .grid {
      height: fit-content;
      margin-top: auto;
      background: rgb(255 255 255 / 0.5);
      backdrop-filter: blur(8px);
      padding-block: sprem(10);


    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    inset: 0;
    pointer-events: none;
    z-index: 1;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
    @include mq(sp) {
      opacity: 0;
      
    }


  }

  &:hover {
    .font-jp,
    .font-en {
      color: var(--clr-wht);
    }
    &::before {
      inset: 0;
      pointer-events: none;
      z-index: 1;
      opacity: 0.1;
    }
  }
}
.related_page--list .font-jp {
  transition: var(--transit-default);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: rem(32);
  @include mq(sp) {
    width: 90%;
    font-size: sprem(18);
    padding-block: sprem(10);
    row-gap: sprem(8);
    column-gap: sprem(8);
    display: grid;
    text-align: left;
    grid-template-columns:  1fr auto;

    .related_page--main {
      grid-column: 1;
      grid-row: 1;
      font-size: sprem(18);
    }

    .arrow  {
      grid-column: 2;
      grid-row: 1;
    }

  }
}
.related_page--list .font-en {
  transition: var(--transit-default);
  opacity: 0.5;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
  width: 100%;
  line-height: 1.6;
  font-size: calc-fz(14);
  color: var(--clr-bg-02);
  font-family: var(--font-en);
  padding-block: rem(5);
  &1 {
    border-bottom: 1px solid;
  }
  &2 {
    border-top: 1px solid;
  }
  @include mq(sp) {
    font-size: sprem(10);
    display: none;
  }
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  z-index: -1;
  filter: blur(4px);
  @include mq(sp) {
    opacity: 1;
    display: block;
    z-index: -1;
    position: relative;
    filter: blur(0);
  }
}
.subpage .related_page--list .sp_subtxt {
  display: none;
}
@include mq(sp) {
  .subpage .related_page--bg {
    display: none;
  }
  .subpage .related_page--list {
    row-gap: 0;
  }
  .subpage .related_page--list li{ 
    border-bottom: 1px solid var(--clr-bg-02);
  }
  .subpage .related_page--list li:first-of-type {
    border-top: 1px solid var(--clr-bg-02);
  }
  .subpage .related_page_wrap {
    padding-top: sprem(64);
  }
  .subpage .related_page--list .font-jp {
    font-size: sprem(20);
    width: 80%;
  }   
  .subpage .related_page--list a:hover .font-jp {
    color: var(--clr-text-secondary);
  }

  .subpage .related_page--list .sp_subtxt {
   font-size: sprem(12);
   color: var(--clr-bg-02);
   text-transform: capitalize;
   display: block;
  }
  .subpage .related_page--list .font-jp .arrow {
    grid-row: 1 / 3;
  }
  .subpage .related_page--list a .grid {
    padding-block: sprem(12);
  }
}

.round_arrow,
.related_page--list,
.circle-arrow {
  .arrow {
    width: rem(48);
    height: rem(48);
    background: var(--clr-primary-default);
    display: flex;
    justify-content: center;
    border-radius: 100%;
    transition: var(--transit-default);
  }
  .arrow svg {
    height: 100%;
    color: var(--clr-wht);
  }

  a:hover {
    .related_page--bg {
      opacity: 0.5;
    }
    .arrow {
      background: var(--clr-primary-light);
    }
  }
}

.round_arrow a {
  display: flex;
  align-items: center;
  column-gap: rem(24);
}
