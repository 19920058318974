@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0, 1fr) rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--clr-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.noimg_wrap.post--img .img-contain {
  max-width: 30%;
}

.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-primary-default);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  opacity: 0.5;
  @include mq(sp) {
    margin-bottom: sprem(10);
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: calc-fz(16);
  background-color: var(--clr-primary-default);
  color: var(--clr-wht);
  padding-inline: 1em;
  line-height: 2;
}
.cat_list a:hover {
  opacity: 0.7;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl span {
  display: block;
  margin-top: rem(8);
  line-height: 1;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  opacity: 0.5;
}

/* --- list --- */
.side--list a {
  display: block;
}
.side--list .children a {
  position: relative;
  padding-left: 1.5em;
  &::before {
    content: "";
    position: absolute;
    top: 0.75em;
    left: 0;
    display: block;
    width: 1em;
    border-top: solid 1px;
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0, 1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: 0.25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-bg-02);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: 0.25em 4em 0.25em 2em;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0.4em;
      right: 0.8em;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -0.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* blog
**************************************** */
.posts-blog {
  .post {
    display: grid;
    gap: rem(40);
    padding-block: rem(48);
    border-bottom: 1px solid var(--clr-mono-02);
    @include mq(sp, min, ps) {
      grid-template-columns: rem(280) minmax(0, 1fr);
      align-items: center;
    }
    @include mq(sp) {
      gap: sprem(24);
      padding-block: sprem(32);
    }
    &:first-child {
      border-top: 1px solid var(--clr-mono-02);
    }
  }
  .post--txtarea {
    flex: 1;
    word-break: break-word;
  }
  .post--ttl {
    font-size: calc-fz(18);
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- posts-news --- */
.posts-news {
  .post {
    gap: rem(40);
    padding-bottom: rem(40);
    padding-top: rem(40);
    border-bottom: 1px solid var(--clr-mono-02);
    &:first-child {
      border-top: 1px solid var(--clr-mono-02);
    }
    @include mq(sp) {
      display: block;
      padding-bottom: rem(20);
      padding-top: rem(20);
    }
  }
  .post--info {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--txtarea {
    flex: 1;
  }
  .post--ttl {
    font-size: calc-fz(18);
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: rem(16);
  }
}

.posts-news .cat_list a {
  padding: rem(5) rem(12);
  color: var(--clr-primary-default);
  border: 1px solid var(--clr-primary-default);
  border-radius: rem(500);
  font-size: calc-fz(14);
  background: transparent;
  line-height: 1.4;
}

/* job
**************************************** */
.posts-job {
  .post {
    padding: rem(16);
    background: var(--clr-wht);
    border: 1px solid var(--clr-bg-02);
    transition: var(--transit-default);
    &:hover {
      background: var(--clr-bg-01);
    }
  }
  .post--txtarea {
    padding: rem(24) rem(20);
  }


}
.cat_list a.new-graduates {
  background-color: var(--clr-primary-default);
}
.cat_list a.career {
  background-color: var(--clr-secondary-default);
}

/* requirement
********************************************** */
.requirement--table {
  width: 100%;
  font-size: calc-fz(16);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.requirement--table th {
  width: 25%;
  text-align: left;
  font-weight: normal;
  font-weight: bold;
  @include mq(sp) {
    display: block!important;
  }
}
.requirement--table td {
  font-weight: bold;
  @include mq(sp) {
    display: block!important;
  }
}

.requirements--nav-link {
  position: relative;
  display: inline-block;
  min-width: 200px;
  padding: 16px 32px;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.3em;
  border: 2px solid;
  transition: all 0.5s cubic-bezier(0.51, 0.01, 0, 0.98);
  background: var(--clr-wht);
}

.requirements--nav-link:hover {
  transform: scale(0.94);
}

li:not(:last-child) .requirements--nav-link {
  margin-right: 32px;
  @include mq(sp) {
   margin-right: 0;
   margin-bottom: sprem(20);
  }
}

.requirements--nav-link.new-graduates {
  color: var(--clr-primary-default);
}

.requirements--nav-link.career {
  color: var(--clr-secondary-default);
}

/* interview
********************************************** */
.posts-interview {
  column-gap: 1px;
  row-gap: 1px;
  padding: 1px;
  @include mq(sp, min, ps) {
  
    background-color: var(--clr-bg-02);
  }
  .post {
    padding: rem(40);
    background: var(--clr-wht);
    transition: var(--transit-default);
    &:hover {
      background: var(--clr-bg-01);
    }
  }
  .post--info {
    @include mq(sp) {
      margin-bottom: sprem(10);
    }
  }

  .post--ttl a {
    display: inline-block;
    background: var(--color-gradation-gradation01);
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
    width: fit-content;
  }
}

.archive .posts-interview {
  background: none;
  row-gap: rem(32);
  column-gap: rem(32);
}

.archive .posts-interview .post {
  border: 1px solid var(--clr-bg-02);
}
