@use "../abstracts" as *;

.page-recruit.recruit-top {

  .lps_sec:nth-child(even) {
    background-color: var(--clr-bg-01);
    .ttl-01.font-en .maintitle {
      line-height: .88;
    }
  }
  .lps_sec:nth-child(odd) {
    background-color: var(--clr-wht);
  }
  .lps_sec:nth-child(4) {
   &::before {
    content: '';
    width: rem(652);
    height: rem(560);
    display: block;
    position: absolute;
    bottom: 0;
    left: rem(28);
    background: url('../images/recruit/bg-deco01.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    
    @include mq(sp) {
      left: 0;
      width: 100%;
    }
   }
  }
}

.recruit_hero_back {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;

}

.recruit_hero_wrap {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  >* {
    grid-row: 1;
    grid-column: 1;
  }
}

.recruit_hero_back {

  .deco,
  .deco2 {
    position: absolute;

  }

  .deco {
    left: 0;
    bottom: 0;
  }

  .deco2 {
    right: 0;
    top: 0;
  }
}

.recruit_hero_wrap {
  background: var(--clr-bg-01);
}

.recruit_hero {
  padding-top: rem(240);
  @include mq(sp) {
    padding-top: sprem(54);
  }
}


.recruit_ttl {
  font-size: calc-fz(64);
  font-family: var(--font-jp);
  line-height: 1.4;
  letter-spacing: .03em;
  margin-bottom: -2em;
  position: relative;
  z-index: 1;
  @include mq(sp) {
     font-size: sprem(32);
  }
}

.recruit_hero_wrap .recruit_ttl--txtslide {
  position: relative;
}

.recruit_ttl--txtslide .txt_slide--item {
  color: var(--clr-wht);
}

.fix_area_hero--bg {
  position: relative;
  @include mq(sp, min, ps) {
    height: 100vh !important;
    position: sticky;
    top: 0;
  }
}

/* recruit_message
********************************************** */
.recruit_message--txtbox {
  font-size: calc-fz(24);
  position: relative;
  z-index: 1;
  @include mq(sp) {
    font-size: sprem(15);
    
   padding-inline: 5%;
  }
}
.recruit_message {
  @include mq(sp) {
    .btn {
      width: 60%;
      min-width: auto;
      margin-inline: auto;
    }
  }
}

.recruit_message--txtbox p {
  line-height: 2.2;
}

.recruit_message--deco {
  position: absolute;
  &.deco1 {
    top: 0;
    left: 0;
  }
  &.deco2 {
    bottom: 0;
    right: 0;
  }
  &.deco3 {
    top: rem(140);
    left: 0;

  }
  &.deco4 {
    bottom: rem(140);
    left: 0;
    @include mq(sp) {
      bottom: sprem(70);
    }
  }
  &.deco5 {
    top: rem(140);
    right: 0;
  }
  &.deco6 {
    bottom: rem(140);
    right: 0;
    @include mq(sp) {
      bottom: sprem(70);
    }
  }
  @include mq(sp) {
    width: 15%;
  }
}



/* recruit_personality_list--item
********************************************** */
.recruit_personality {
  position: relative;
  z-index: 1;
  padding-bottom: rem(220);
  background: #F7C3D5;
  @include mq(sp) {
    padding-bottom: sprem(120);
  }
  &::after {
    position: absolute;
    content: '';
    width: calc(100% - ((100vw - var(--scrollbar-width)) / 2 - rem($inner_half)));
    height: calc(100% + 1px);
    background-color: var(--clr-bg-01);
    left: 0;
    top: 0;
    z-index: -1;
    @include mq(sp) {
      max-width: 100%;
    }
  }
}


.recruit_personality .recruit_personality--txtslide {
  bottom: rem(40);
  line-height: normal;

}

.recruit_personality .txt_slide--item {
  color: var(--clr-wht);
  @include mq(sp) {
   line-height: normal;
  }
}

.recruit_personality_list {
  position: relative;
  z-index: 1;
  @include mq(sp) {
    grid-template-columns: 100%;
    padding-right: 0!important;
    row-gap: 0!important;
  }
}

.recruit_personality_list--item {
  padding: rem(80);
  position: relative;
  place-content: center;

  aspect-ratio: 1 / 1;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 4;
  row-gap: rem(16);
  @include mq(sp) {
    padding-block: sprem(60);
    padding-inline: sprem(40);
    margin-bottom: sprem(40);
  }


}

.recruit_personality_list--item .icon {
  display: flex;
  place-content: center;
}

.recruit_personality_list--item p {
  text-align: justify;
}
.recruit_personality_list--item .ttl-03:not([class*=mgn-btm]):not(:last-child)  {
  margin-bottom: 0;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.recruit_personality_list--clipwrap {
  position: absolute;
  width: calc(100% + rem(46));
  top: 0;
  left: 50%;
  translate: -50% 0;
  aspect-ratio: 1 / 1;
  @include mq(sp) {
    width: calc(100% + sprem(24));
    svg {
      width: 100%;
      height: 100%;
    }
  }

}

.recruit_personality_list {
@include mq(sp, min, ps) {
  padding-right: rem(160 - 64);
}
}

.recruit_personality_list--item:nth-of-type(2) {
  @include mq(sp, min, ps) {
  translate: rem(-32);
  }
}

.recruit_personality_list--item:nth-of-type(3) {
  @include mq(sp, min, ps) {
  translate: rem(-64);
  }
}

/* recruit_intoro
********************************************** */
.recruit_intoro_deco {
  position: absolute;
  bottom: 0;
  right: 0;
}

.recruit_intoro_inner {
@include mq(sp, min, ps) {
  padding-left: rem(160);
}
}

.recruit_interview .home_interview_txt {
  justify-content: flex-end;
}


.recruit_intoro::after {
  content: '';
  position: absolute;
  width: calc(100% - (50% - rem($inner_half)));
  height: 100%;
  background: var(--clr-bg-01);
  right: 0;
  z-index: -1;
  top: 0;
  border: 1px solid var(--clr-bg-02);
  border-width: 1px 0 0 1px;
}


.recruit_intoro-wrap {
  display: grid;
  grid-template-columns: 100%;
  row-gap: rem(80);
  position: relative;
  z-index: 1;
}

.recruit_intoro--item {
  display: grid;
  grid-template-columns: rem(528) 1fr;
  column-gap: rem(80);
  grid-template-areas:
    'img txt'
  ;
  @include mq(sp) {
    grid-template-columns: 100%;
    grid-template-areas:
    'txt'
    'img'
  ;
}
}

.recruit_intoro--subttl {
  color: var(--clr-text-placeholder);
}

.recruit_intoro--img {
  grid-area: img;
}

.recruit_intoro--txtbox {
  grid-area: txt;
}

.recruit_intoro--txtbox .ttl-02 {
  letter-spacing: .03em;
}

.sub_cont--txt,
.sub_cont--list {
  font-size: calc-fz(16);
  line-height: 2;
}

.recruit_intoro--fukidashi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: rem(32);
  @include mq(sp) {
  grid-template-columns: 100%;
  row-gap: sprem(20);
  }
}

.recruit_intoro--fukidashi.member_interview_item--txtarea::after {
  left: initial;
  right: calc(100% - 1px);
  clip-path: polygon(100% 0, 14% 50%, 100% 100%);
}

.recruit_intoro--item:nth-of-type(2n) {
  grid-template-columns: 1fr rem(528);
  grid-template-areas:
    'txt img'
  ;
  @include mq(sp) {
  grid-template-columns: 100%;
  grid-template-areas:
  'txt'
  'img'
;
  }

  .recruit_intoro--fukidashi.member_interview_item--txtarea::after {
    right: initial;
    left: calc(100% - 1px);
    clip-path: polygon(0 0, 0% 100%, 86% 50%);
    @include mq(sp) {
      display: none;
    }
  }
}

.sub_cont--list li {
  position: relative;
  padding-left: rem(18);
  line-height: 2;

  &::after {
    content: '';
    display: block;
    position: absolute;
    float: inline-start;
    top: 0;
    left: 0;
    width: rem(8);
    aspect-ratio: 1 / 1;
    margin-top: calc((1lh - rem(8)) / 2 - 1px);
    background: var(--clr-primary-light);
    border-radius: 100%;

  }
}
.recruit_interview {
  background: var(--illust-blue04);
} 
.recruit_interview.home_interview::before {
  background: var(--clr-wht);
}

/* recruit_job
********************************************** */
.recruit_job--txt {
  padding-inline: rem(80);
  padding-block: rem(40);
  display: grid;
  grid-template-columns: 100%;
  place-items: center normal;
  place-content: center normal;
  background: var(--clr-bg-01);
  position: relative;
  @include mq(sp) {
    padding-inline: sprem(40);
  }
}

.recruit_job--txt .ttl-01 {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: baseline;
  column-gap: rem(16);
  .maintitle {
    margin-bottom: 0;
  }
  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: sprem(20);
  }
}

.recruit_job--inner {
  border: 1px solid var(--clr-bg-02);
}

.recruit_job--txt--deco {
  position: absolute;
  right: rem(80);
  bottom: rem(64);
  @include mq(sp) {
    bottom: initial;
    top: sprem(-10);
    width: 30%;
    right: sprem(10);
  }
}


/* recruit_stickywrap
********************************************** */
.recruit_stickywrap_scrollcont--header,
.recruit_stickywrap {
  display: grid;
  grid-template-columns: 100%;

  >* {
    grid-column: 1;
    grid-row: 1;
  }

}

.recruit_stickywrap .lps_sec {
  @include mq(sp) {
  z-index: 1;
  }
}

.recruit_stickywrap--stickycont_inner {
  position: sticky;
  top: 0;
  grid-template-columns: 100%;
  @include mq(sp) {
  z-index: -1;
  }
}

.recruit_stickywrap--stickycont_inner .txt_slidewrap {
  bottom: initial;
  top: 50%;
  translate: 0 -50%;
  line-height: normal;

}


.recruit_stickywrap_scrollcont--header_back--inner,
.recruit_stickywrap--stickycont_inner {
  height: 100vh;
}


.recruit_stickywrap_scrollcont--header_back,
.recruit_stickywrap--stickycont {
  padding-top: rem(260);
}

.recruit_stickywrap_scrollcont--header_back {
  opacity: 0;
  pointer-events: none;
}

/* benefits
********************************************** */
.benefits--wrap {
  @include mq(sp) {
  
    padding-top: sprem(60);
    padding-bottom: sprem(60);
  }
}



.benefits--mainttlwrap {
  padding-left: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));
}

.benefits--wrap .scroll-list {
  align-items: center;
}

.benefits--wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  counter-reset: section;
  column-gap: rem(140);
  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: 0;
  }
}

.benefits--mainttl {
  height: 100vh;
  display: flex;
  align-items: center;
  @include mq(sp) {
    height: fit-content;
    width: 100%;
    padding-inline: 5%;
  }
}

.benefits--item {
  display: grid;
  grid-template-columns: rem(392) 1fr;
  margin-right: rem(80);
  column-gap: rem(80);
  height: 100%;
  align-items: center;
  @include mq(sp) {
    grid-template-columns: 100%;
    margin-right: 0;
    padding-inline: 5%;
  }
}
.benefits--img {
  @include mq(sp) {
    grid-row: 2;
    text-align: center;
  }
}
.benefits--txtwrap {
  width: rem(704);
  position: relative;
  display: flex;
  padding-block: rem(56);
  flex-direction: column;
  @include mq(sp) {
    width: 100%;
    grid-column: 1;
  }

  &::before {
    counter-increment: section;
    content: counter(section, decimal-leading-zero);
    position: absolute;
    font-size: calc-fz(200);
    font-family: var(--font-en);
    color: var(--clr-bg-01);
    letter-spacing: .03em;
    line-height: .8;
    right: 0;
    top: rem(56);
    font-weight: bold;
    z-index: -1;
    @include mq(sp) {
      font-size: sprem(140);
    }
  }
}

.benefits--txt {
  margin-bottom: rem(80);
  @include mq(sp) {
    margin-bottom: 0;
  }
}


.benefits--ttl {
  font-size: calc-fz(48);
  font-family: var(--font-jp);
  letter-spacing: 0.03em;
  line-height: 1.5;
  margin-bottom: rem(40);
  color: var(--clr-text-secondary);
  @include mq(sp) {
    font-size: sprem(32);
  }
}

.benefits--item .txt_acc2 {
  margin-bottom: rem(35);
}

.benefits--item .txt_deco {
  background-color: var(--clr-bg-01);
}

.benefits--item .txt_deco::after {
  background-color: var(--clr-bg-01);
}

.benefits-header {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: rem(160);
  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: sprem(80);
  }
}

.home_business_txt--numitem:first-of-type .slide_num--item {
  padding-left: 0;
}


.benefits--coutwrap {
  place-items: center normal;
  margin-top: auto;
  @include mq(sp) {
    margin-top: sprem(20);
  }
}
.benefits--back {
  @include mq(sp) {
    display: none;
  }
}
.benefits--back.deco1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.benefits--back.deco2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.page-recruit .home_interview_inner {
  place-items: center normal;
}