@use "../abstracts" as *;

/* hero
********************************************** */
.hero {

    position: sticky;
    top: 0;
    height: 100vh;

  @include mq(sp) {
    height: auto;
    min-height: 100vh;
    padding:  sprem(40) 0;
  }
}

.hero--cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq(sp, min, ps) {
    position: absolute;
    height: 100vh;
    top: 50%;
    left: calc(50vw - rem($inner_half));
    translate: 0 -50%;
  
  }

  transition: var(--transit-default);
  @include mq(sp) {
    left: 5%;
    padding-inline: sprem(40);

  }
}

.hero-sm .hero--cont {
  opacity: 0;
}

.hero--catch_en {
  font-size: calc-fz(20);
  line-height: 1.7;
  color: var(--clr-secondary-default);
  margin-bottom: rem(16);
  @include mq(sp) {
  font-size: sprem(18);
  }
}

.hero--catch {
  font-size: calc-fz(56);
  line-height: 1.4;
  letter-spacing: 0.03em;
  margin-bottom: rem(24);
  font-family: var(--font-secondary);
  @include mq(sp) {
    font-size: sprem(32);
    }
}

.hero--lead {}

.hero_slide {
  overflow: hidden;
}
.hero_slide .img-trim {
  display: flex!important;
  align-items: center;
  justify-content: flex-end;
}

.hero_comment {
  position: absolute;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
  opacity: 0;
  @include mq(sp) {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.2));
  }
}

.hero_comment_1 {
  top: rem(160);
  left: rem(310);
  @include mq(sp) {
    left: sprem(10);
    top: sprem(10);
  }
}

.hero_comment_2 {
  right: rem(207);
  bottom: rem(264);
  @include mq(sp) {
    right: sprem(10);
    bottom: sprem(10);
  }
}

.hero_comment_2.txt_acc2 .txt_deco::after {
  top: initial;
  bottom: calc(100% - 1px);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

}

.slick-active .hero_comment {

  // animation: poyoyon 0.6s ease-out forwards;
}

.slick-active .hero_comment_1 {
  animation-delay: 0.5s;
  /* Aはすぐに開始 */
}

.slick-active .hero_comment_2 {
  animation-delay: 1s;
  /* Bは0.5秒遅れて開始 */
}


.hero_slide-sp {
  display: none !important;
}

.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}

.hero_slide {
  width: 65%;
  @include mq(sp) {
  width: 100%;
  }
}

.hero_slides {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include mq(sp) {
    height: fit-content;
    justify-content: center;
    margin-bottom: sprem(40);
  }

}

.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

.hero_slide .slick-slide img {
  width: 100% !important;
  max-height: rem(960);
  object-fit: cover;
  object-position: left center;
  @include mq(sp, min, ps) {
    height: 100vh !important;
  }
   @include mq(sp) {
   height: auto;
   }
}

/* --- hero--scroll --- */
.hero--scroll {
  position: absolute;
  left: rem(80);
  bottom: rem(80);
  z-index: 2;
  display: inline-block;
  padding-bottom: rem(40);
  font-size: .938rem;
  /* 15px */
  line-height: 1;
  letter-spacing: .15em;
  writing-mode: vertical-lr;
  overflow: hidden;
  font-family: var(--font-en);
  color: var(--clr-text-secondary);
  font-size: rem(18);
}

.hero--scroll::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50% 0;
  width: rem(8);
  height: rem(32);
  background-image: url("../images/index/hero-icn_scroll.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


@media screen and (max-width: 767px) {

  /* --- hero--scroll --- */
  .hero--scroll {
    left: auto;
    right: 2.7vw;
    bottom: 0;
    transform: scale(.6, .6);
    transform-origin: right bottom;
  }
}

/* home_about
********************************************** */
.home_about {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
}


html .home_about_slidetxt {
  position: relative;
}

html .home_about_slidetxt .txt_slide--item {
  color: rgba(255, 255, 255, 0.9);
}

/* home_news
********************************************** */
.home_news {
  padding: rem(120);
  background-color: var(--clr-wht);
  border-bottom: 1px solid #eee;
  @include mq(sp) {
    padding: rem(40);
    border: none;
  }
}

.home_news .posts-news {
  flex: 1;
  @include mq(sp) {
  margin-bottom: sprem(40);
  }
}

.home_news--flex {
  column-gap: rem(160);
}

.posts-news--ttl {
  display: block;
  position: relative;
  margin-right: rem(40);
  padding: 0;
  line-height: 1.2;

  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: sprem(16);
  }
}

/* home_related
********************************************** */

.home_related {

  @include mq(sp) {
  background: var(--clr-bg-01);
  position: relative;
  }
}

.home_related .related_page--list {
  border: 1px var(--clr-bg-02) solid;
  border-width: 1px 0;
  grid-template-columns: repeat(auto-fit, minmax(calc((100% - 3px) / 4), 1fr));
  aspect-ratio: 80 / 21;
  width: 100%;
  height: 100%;
  @include mq(sp) {
    aspect-ratio: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    border: none;
  }



  .grid {
    height: 100%;
  }
  @include mq(sp) {
    .grid {
      height: fit-content;
      margin-top: auto;
      background: rgb(255 255 255 / 0.5);
      backdrop-filter: blur(8px);
      padding-block: sprem(10);
    }
  }
}
.home_related .related_page--list .font-jp { 
  @include mq(sp) {

  grid-template-columns:  1fr auto;

  .related_page--main {
    grid-column: 1;
    grid-row: 1;
    font-size: sprem(18);
  }
  .related_page--list_subtxt {
    grid-column: 1;
    grid-row: 2;
  }
  .arrow  {
    grid-column: 2;
    grid-row: 1 / 3;
  }
}
}


.related_page--list_subtxt {
  font-size: calc-fz(16);
  color: var(--clr-wht);
  display: none;
  font-weight: normal;
  @include mq(sp) {
  display: block!important;
  color: var(--clr-primary-default);
  }
}

/* home_data
********************************************** */
.home_data {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - (50% - rem($inner_half)));
    max-width: 100%;
    height: 100%;
    background: var(--clr-bg-01);
    top: 0;
    left: 0;
    z-index: -1;
    border: 1px solid var(--clr-bg-02);
    border-width: 1px 1px 0;
    @include mq(sp) {
    border-width: 1px 0;
    }
  }
}

.home_data--ttl {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: rem(160);

  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: 0;
    margin-bottom: sprem(60);
  }
}

.home_data--conttxt {
  @include mq(sp, min, ps) {
    padding-top: rem(32);
  }
}

.home_data_acc {
  top: rem(-160);
  right: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half) + rem(66));

  @include mq(sp) {
    right: 0;
    width: 30%;
    top: 0;
    translate: 0 -60%;
  }
}


/* --- home_data--item --- */
.home_data--list--wrap {
  @include mq(sp, min, ps) {
    padding-left: calc(50% - rem($inner_half));
    padding-right: rem(24);
  }
}

.home_data--list {


  background: url("../images/index/data-bg.png"), var(--clr-primary-default);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.home_data--item {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-auto-rows: auto;
  row-gap: rem(16);
  padding-inline: rem(24);
  padding-block: rem(48);
  place-items: center center;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #fff;
  transition: .5s ease;
  opacity: 0;
  translate: 0 -20%;
  @include mq(sp) {
    padding-block: sprem(24);
  }

}

.home_data--item.parent-animation {
  opacity: 1;
  translate: 0 0;
}

.home_data--caption {
  display: inline-block;
  width: fit-content;
  padding-inline: rem(32);
  padding-block: rem(8);
  background: var(--clr-wht);
  border-radius: rem(500);
  color: var(--clr-secondary-default);
  font-weight: bold;
  font-size: calc-fz(20);
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(14);
    padding-inline: sprem(24);
  }
  .bold {
    font-size: calc-fz(24);
    color: var(--clr-secondary-default);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    translate: 0 -50%;
    width: rem(24);
    height: rem(24);
    background-image: url("../images/common/fukidashi2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@include mq(sp, min, ps) {
  .home_data--item:nth-of-type(n+4) {
    border-bottom: none;
  }
}
.home_data--item:last-of-type {
  border-bottom: none;
}
.home_data--num {
  font-size: calc-fz(96);
  letter-spacing: normal;
  font-family: var(--font-en);
  line-height: 0.88;
  font-weight: bold;
}

.home_data--txt {
  font-size: calc-fz(32);
}

.home_data--caption2 {
  font-size: calc-fz(20);
  line-height: 1;
}

/* business_photo_box
********************************************** */
.home_business {
  position: relative;

  @include mq(sp, min, ps) {
    padding-right: rem(22);
  }

}
.home_business--item .btn {
  @include mq(sp) {
  width: fit-content;
  min-width: auto;
  margin-left: auto;
  }
}


.home_business--item_inner {
  padding-left: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));

  display: grid;
  grid-template-columns: rem(502) calc((60vw - var(--scrollbar-width)));
  gap: rem(80);

  @include mq(sp) {
    grid-template-columns: 100%;
  }
}

.home_business_photo_box,
.home_business_photo_boxsp {
  padding-top: rem(80);
  padding-bottom: rem(80);
  position: relative;
  flex: 0 0 auto;

  @include mq(sp, min, ps) {
    width: calc((60vw - var(--scrollbar-width)));
  }
  @include mq(sp) {
    width: 90%;
    margin: 0 auto;
    padding-top: sprem(40);
    margin-bottom: sprem(54);
    padding-bottom: sprem(110);
  }


}

.business_photo_box--item {
  width: calc(100% - rem(320));
  margin-left: auto;
  @include mq(sp) {
    width: 65%;
  }
  &2 {
    width: rem(400);
    height: rem(400);
    bottom: 0;
    left: 0;
      position: absolute;

    @include mq(sp) {
    width: 100%;
    height: fit-content;
    img {
      width: 40%!important;
    }
    }
  }
}

.business_photo_box--item .comment {
  position: absolute;
  padding-inline: rem(32);
  padding-block: rem(16);
  background: var(--clr-wht);
  border-radius: rem(500);
  display: inline-block;
  top: rem(120);
  left: rem(10);
  font-size: calc-fz(18);
  box-shadow: 0px 0px 24px 0px rgba(141, 146, 158, 0.20);

  &::before {
    content: '例えば…';
    color: var(--clr-bg-02);
    font-size: calc-fz(14);
    line-height: 1.6;
    position: absolute;
    top: 0;
    left: rem(14);
    translate: 0 calc(-100% - rem(8));
  }

  &::after {
    content: '';
    position: absolute;
    width: rem(40);
    height: rem(27);
    top: calc(100% - rem(5));
    left: 50%;
    translate: -50% 0;
    background-image: url(../images/common/fukidashi3.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    @include mq(sp) {
      left: sprem(24);
      translate: 0;
    }

  }
  @include mq(sp) {
   font-size: sprem(12);
   width: fit-content;
   top: sprem(20);
   padding-inline: sprem(24);
  }
}

.business_photo_box--item2 .comment {
  translate: 0 calc(-100% - rem(60));
  top: 0;
  @include mq(sp) {

  translate: 0;
  bottom: sprem(-20);
  top: initial;
  left: 28%;
  &::after {
    scale: -1 -1;
    left: sprem(32);
    top: initial;
    bottom: calc(100% - rem(5));
  }
  &::before {
    left: sprem(56);
  }
  }
}

.home_business_txt_box {
  width: rem(502);
  counter-reset: section;
  height: fit-content;
  max-width: 100%;

}

.home_business_txt_box_inner {
  padding-top: rem(58);
  @include mq(sp) {
    width: 90%;
    margin-inline: auto;
    padding-top: 0;
  }
}

.home_business_txt_box_ttl {
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;

  @include mq(sp, min, ps) {
    >* {
      grid-column: 1;
      grid-row: 1;
    }
  }
  margin-bottom: rem(170);
  @include mq(sp) {
    row-gap: sprem(80);
    margin-bottom: 0;
  }
}

.home_business_txt--item {
  opacity: 0;
  transition: var(--transit-default);

  &.is-active {
    opacity: 1;
  }

  position: relative;
  padding-top: rem(22);

  &::before {
    counter-increment: section;
    content: counter(section, decimal-leading-zero);
    position: absolute;
    font-size: calc-fz(200);
    font-family: var(--font-en);
    color: var(--clr-wht);
    letter-spacing: .03em;
    line-height: .8;
    right: 0;
    top: 0;
    font-weight: bold;

    @include mq(sp) {
      z-index: -1;
    }
  }

  @include mq(sp) {
    opacity: 1;
    z-index: 1;
  }
}

.home_business_txt_box.is-active .home_business_txt--item:last-of-type {
  opacity: 1;
}

.home_business_txt--ttl {
  display: flex;
  flex-direction: column;
  row-gap: rem(16);
  margin-bottom: rem(56);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }

  .subtitle:not([class*=mgn-btm]):not(:last-child) {
    margin-bottom: 0;
    font-family: var(--font-en);
    color: var(--clr-text-placeholder);
    line-height: 1.8;
    font-size: calc-fz(18);
    letter-spacing: normal;
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  .maintitle {
    color: var(--clr-text-secondary);
    font-size: calc-fz(48);
    line-height: 1.5;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}

.home_business_txt--txt {
  line-height: 2.2;
  letter-spacing: .03em;
}

.bridge_illust {
  position: absolute;
  right: rem(32);
  bottom: 0;
  display: block;
  opacity: 0;
  transition: var(--transit-default);
  pointer-events: none;

  &.is_acitive {
    opacity: 1;
  }
}
.home_business_txt--num {
  @include mq(sp) {
  display: none;
  }
}


/* home_
********************************************** */

.home_recruit_sectionwrap {
  @include mq(sp) {
  overflow: hidden;
  }
}
.home_recruit_sectionwrap_header {
  position: sticky;
  top: 0;
  height: 100vh;
  @include mq(sp) {
   height: auto;
   width: 100%;
   position: relative;
  }
}

.home_recruit_sectionwrap .txt_slidewrap {
  bottom: initial;
  top: 0;
  translate: 0 -100%;
  overflow: hidden;
  line-height: 1;
  display: block;
  height: rem(180);

  @include mq(sp) {
    white-space: normal;
    height: .85em;
    translate: 0 -1lh;
    overflow: visible;
  }
}

.home_recruit_sectionwrap .txt_slidewrap .txt_slide--item {
  color: rgba(#fff, 0.8);
  animation: none;
  @include mq(sp) {
    white-space: normal;
    font-size: sprem(42);
  }

}

.home_recruit--acc {
  right: rem(132);
  top: 0;
}

.home_recruit--acc2 {
  left: rem(-132);
  bottom: rem(-43);
}

.home_welfare {
  z-index: 1;
}

.home_recruit--acc3 {
  left: 0;
  top: rem(-204);
  z-index: -1;
  @include mq(sp) {
    position: relative;
    top: initial;
  }
}

.home_recruit--acc4 {
  right: 0;
  bottom: rem(-140);
  z-index: 1;
  @include mq(sp) {
    width: 40%;
  }
}


/* home_recruit
********************************************** */
.home_recruit {

  @include mq(sp) {}
}

.home_recruit_inner {}

.home_recruit--txt {
  padding-left: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));
  padding-right: rem(132);
  @include mq(sp) {
    padding-inline: 5%;
    padding-bottom: sprem(40);
  }

}

.home_recruit_photo_box {
  position: relative;
  padding-top: rem(145);
}

.home_recruit_photo_box--item {
  margin-left: auto;
  width: fit-content;

}

.home_recruit_photo_box--item1 {

  @include mq(sp) {
    width: 60%;
  }
}


.home_recruit_photo_box--item2 {
  position: absolute;
  top: 0;
  left: 0;
  @include mq(sp) {
    width: 30%;
  }
}

.home_recruit--back {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  @include mq(sp) {
    top: sprem(80);
    translate: -50% 0;
  } 
}

/* home_welfare
********************************************** */
.home_welfare {
  padding-left: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));
}

.home_welfare_inner {
  background: var(--clr-wht);
  border: 1px solid var(--clr-bg-02);
  border-width: 1px 0 0px 1px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: rem(88);

  @include mq(sp, min, ps) {

    padding-left: rem(140);
  }

  @include mq(sp) {
    width: 100%;
    padding-inline: 5%;
    grid-template-columns: 100%;
    row-gap: sprem(40);
  }
}


/* home_interview_inner
********************************************** */
.home_interview {
  padding-left: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));

  &::before {
    content: '';
    width: calc(100% - ((100vw - var(--scrollbar-width)) / 2 - rem($inner_half)) + 2px);
    max-width: 100%;
    height: calc(100% + 1px);
    background: var(--clr-bg-01);
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid var(--clr-bg-02);
    border-width: 1px 1px 0 0;
    @include mq(sp) {
      border-width: 1px 0 0 0;
    }
  }
}

.home_interview_inner {
  display: grid;
  gap: rem(190);
  grid-template-columns: auto 1fr;

  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: sprem(20);
    width: 90%;
    margin-inline: auto;
  }

}

.home_interview_inner {
  padding-right: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));
}

.home_interview_txt {
  display: flex;
  justify-content: space-between;
  padding-top: rem(40);
  padding-right: rem(80);
  align-items: center;
  height: fit-content;
  @include mq(sp) {
    flex-direction: column;
    column-gap: rem(10);
    align-items: flex-start;
    padding: 0;
  }
}

.home_interview_list {
  border: 1px solid var(--clr-bg-02);
  border-width: 1px 0 1px 1px;
  @include mq(sp) {
   width: 90%;
   margin-inline: auto;
   border-width: 1px;
  }

}

.home_interview_list .post {
  padding: rem(35) rem(40);
  background: var(--clr-wht);
  border-right: 1px solid var(--clr-bg-02);

}

.home_interview_list .slick-track {
  display: flex;
}

.home_interview_list .slick-slide {
  height: auto !important;
}



.home_interview_list .slick-list {
  padding-right: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));

}

.home_interview_list .cat_list {
  display: none;
}

.home_interview_list a {
  background: var(--color-gradation-gradation01);
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  letter-spacing: .03em;

}

.home_interview--slic-control {
  padding-top: rem(70);
  padding-bottom: rem(120);
  @include mq(sp) {
    position: static;
    justify-content: center;
    row-gap: sprem(10);
    padding-top: sprem(10);
    padding-bottom: rem(10);
    .slide_arrow {
      padding: 0 2.5%;
      justify-content: space-between;
      top: 60%;
      translate: 0 -50%;
      position: absolute;
      width: 100%;

    }
    .slick-dots {
      justify-content: center;
    }
  }
}

.home_interview,
.archive-interview,
.single-interview {
  .post {
    transition: var(--transit-default);
  }

  .post::after {
    opacity: 0;
    transition: var(--transit-default);
    pointer-events: none;
  }

  .post:hover::after {
    opacity: 1;
  }
}

@include mq(sp) {
  .home_interview {
    padding-bottom: sprem(80);
  }
  .home_interview_list--num  {
    order: 1;
  }
}

/* related_page
********************************************** */
.related_page--acc {
  top: rem(-24);
  left: rem(-76);
  z-index: 1;
  pointer-events: none;
  @include mq(sp) {
   left: 0;
   width: 20%;
   translate: 0 -80%;
  }
}

.related_page--list2 {
  position: relative;
  border-radius: 0;
  overflow: hidden;
  z-index: 1;

  display: grid;
  @include mq(sp, min, ps) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  }
  @include mq(sp) {
    grid-template-columns: 100%;
    row-gap: sprem(10);
  }
}

.related_page--list2--item {
  display: flex;

  &:nth-child(9n-1) a::before,
  &:nth-child(9n-3) a::before,
  &:nth-child(9n-8) a::before {
    filter: brightness(1.1);
  }

}

.related_page--list2--item a {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(64) rem(144) rem(64) rem(96);
  line-height: var(--line-height-hdr);
  color: var(--clr-primary-default);

  @include mq(sp) {
    padding: sprem(24) sprem(32) sprem(24) sprem(24);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    inset: 0;
    pointer-events: none;
    z-index: -1;
    background-color: var(--clr-wht)
  }

}

.related_page--list2--item .font-jp {
  margin-bottom: 0;
  color: var(--clr-primary-default);
  transition: var(--transit-default);

  @include mq(sp) {
    font-size: sprem(24);
  }
}

.related_page--list2--item a:hover .font-jp {
  color: var(--clr-primary-light);
}

.related_page--list2--item .font-en {
  opacity: .5;
  font-weight: 600;

  @include mq(sp) {
    font-size: sprem(10);
  }
}

.related_page--txt {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.related_page--list2--item .arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: rem(96);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--clr-primary-default);
  transition: var(--transit-default);
  @include rect(48);
  @include mq(sp) {
  right: sprem(16);
  }

}

.related_page--list2--item a:hover .arrow {
  background: var(--clr-primary-light);
}

.related_page--list2--item .arrow svg {
  height: 100%;
  color: var(--clr-wht);
  width: rem(24);
}

.related_page--list2 .icon {
  width: rem(32);
  height: rem(48);
}

.related_page--list2 .cta_contact a {
  column-gap: rem(40);
  width: 100%;
  height: 100%;
  border-radius: 0;

}

.related_page--list2 .cta_contact .font-en {
  letter-spacing: normal;
}

.scroll-wrapper {
  position: relative;
  // height: 100vh;
  overflow: clip;
  /* スクロールを隠す */
  height: fit-content;
}

.scroll-list {
  @include mq(sp, min, ps) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    column-gap: rem(80);
  }
}

.slide-title {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #333;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
  text-align: center;
}

.benefits--wrap {

  .scroll-list,
  .scroll-wrapper {
    height: 100vh;
    @include mq(sp) {
      height: auto;
    }
  }
}