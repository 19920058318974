@use "../abstracts" as *;
/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slide_arrow {
    display: flex;
    justify-content: flex-end;
    column-gap: rem(16);
    padding-right: calc((100vw - var(--scrollbar-width)) / 2 - rem($inner_half));
}

.slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    transition: var(--transit-default);
    @include rect(48);
}

.slide_arrow .slick-arrow {
    background: var(--clr-primary-default);

}

.slick-arrow svg {
    height: 100%;
    color: var(--clr-wht);
    width: rem(24);
}

.slide_arrow  .slick-prev {
    rotate: 180deg;
}
.slide_num  .slick-dots { 
    display: flex;

 }

.slide_num--item  {
    padding: 0 rem(24) 0 ;
    border: none;
    font-size: calc-fz(18);
    font-family: var(--font-en);
    color: var(--clr-text-placeholder);
    position: relative;
    display: block;
    cursor: pointer;
    transition: var(--transit-default);
    &::after {
        content: '';
        width: 1px;
        height: rem(16);
        position: absolute;
        right: 0;
        top: 50%;
        translate: 0 -50%;
        background: var( --clr-text-placeholder);

    }
}
.slide_num  .slick-dots li:first-of-type .slide_num--item {
    padding-left: 0;
 }

 .slick-active .slide_num--item  {
    color: var(--clr-secondary-default);

 }