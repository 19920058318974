@use "../abstracts" as *;

/* Wysiwyg editor
**************************************** */
/* --- 共通 --- */
.editor-styles-wrapper,
.mce-content-body {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
  ul:where(:not([class*="ez-toc-"])),
  ol {
    margin-inline-start: rem($list-start);
    &:not(:first-child) {
      margin-top: 1em;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  ol {
    list-style-type: decimal-leading-zero;
    padding-left: 1em;
    @include mq(sp) {
    padding-left: 1.5em;

    }
  }
    li {
    position: relative;
    padding-block-end: 0.5em;
  }
  ul:where(:not([class*="ez-toc-"])) li {
    list-style: none;
    &::before {
      content: "";
      float: inline-start;
      inline-size: rem($list-dot);
      aspect-ratio: 1;
      margin-block: calc((1lh - rem($list-dot)) / 2);
      margin-inline-start: calc(rem($list-start) * -1);
      color: var(--clr-primary-default);
      border: 1px solid;
      background-color: currentColor;
    }
  }
  ol li {
    &::marker {
      color: var(--clr-primary-default);
      font-weight: 600;
      letter-spacing: perc(3.2, 16, em);
    }
  }
  ul[style="list-style-type: circle;"] li::before {
    background-color: var(--clr-wht);
  }
  ul:not([style="list-style-type: square;"]) li::before {
    border-radius: 50%;
  }
  .wp-block-image img {
    margin: 1em 0;
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    width: fit-content;
    max-width: 100%;
  }
  hr {
    margin-top: rem(32);
    margin-bottom: rem(32);
    border: none;
    border-bottom: 1px solid var(--clr-text-placeholder);
  }
  a .wp-block-image img {
    transition: var(--transit-default);
  }
  a:hover .wp-block-image img {
    opacity: 0.8;
  }
  img {
    display: block;
  }
  .alignright,
  .alignleft {
    @include mq(sp) {
      width: 100% !important;
      float: none;
      margin: 1em auto;
      display: block;
    }
  }
  .alignleft {
    float: left;
    clear: left;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .alignright {
    float: right;
    clear: right;
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .aligncenter {
    @include auto-margin;
    margin-bottom: 1em;
  }
  .wp-block-image img.aligncenter {
    display: block;
  }
  .alignleft:last-child,
  .alignright:last-child,
  .aligncenter:last-child {
    margin-bottom: 0;
  }
  p:not(:last-child) {
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  blockquote {
    margin: 1em 0;
    padding: rem(16) rem(40);
    border-radius: rem(3);
    position: relative;
    display: inline-block;
    font-style: oblique;
    color: #666;
    background-color: var(--clr-wht);
    text-align: left;
    @include mq(sp) {
      padding: rem(16);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 1em;
      height: 1em;
      opacity: 0.25;
    }
    &::before {
      background-image: url("../images/common/icon-quote_left.svg");
      top: rem(14);
      left: rem(12);
      @include mq(sp) {
        top: 0;
        left: 0;
      }
    }
    &::after {
      background-image: url("../images/common/icon-quote_right.svg");
      bottom: rem(14);
      right: rem(12);
      @include mq(sp) {
        bottom: 0;
        right: 0;
      }
    }
  }
  blockquote p {
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  table {
    border: none;
    height: auto !important;
    @include mq(sp) {
      max-width: none !important;
    }
    &:not(:first-child) {
      margin-top: 1em;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  tr,
  th,
  td {
    height: auto !important;
  }
  th,
  td {
    padding: 1em;
    border: 1px solid var(--clr-mono-02);
    vertical-align: top;
    @include mq(sp) {
      padding: 0.75em;
      line-height: var(--line-height-hdr);
    }
  }
  th {
    min-width: rem(120);
    text-align: left;
    @include mq(sp) {
      font-weight: bold;
    }
  }
  sup {
    font-size: calc-fz(12);
    color: #555;
  }
  iframe {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    vertical-align: bottom;
    @include mq(sp) {
      &[src*="google.com/maps"] {
        aspect-ratio: 4/3;
      }
    }
    &:not(:first-child) {
      margin-top: 2em;
    }
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}

/* --- ブロックエディタ --- */
.editor-styles-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-jp);
    &:not(:first-child) {
      margin-top: 2em;
      @include mq(sp) {
        margin-top: 1.5em;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1em;
      @include mq(sp) {
        margin-bottom: 0.75em;
      }
    }
  }
  h1,
  h2 {
    font-size: calc-fz(32);
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
  h3 {
    font-size: calc-fz(26);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  h4 {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
  h5 {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  h6 {
    font-size: calc-fz(17);
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
}

.bold {
  font-weight: bold;
  &:not([class*="clr-"]) {
    color: var(--clr-secondary-dark);
  }
}

@include mq(sp) {
  /* table scroll */
  .table-sp-scroll {
    min-width: sprem(540);
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: calc-fz(14);
  }
}

/* AddQuicktag
*************************************************** */
/* 見出し */
.mce-ttl-01,
.mce-ttl-02,
.mce-ttl-03,
.is-style-mce-ttl-01,
.is-style-mce-ttl-02,
.is-style-mce-ttl-03 {
  font-family: var(--font-jp);
  color: var(--clr-text-secondary);
  &:not(:first-child) {
    margin-top: 2em;
    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1em;
    @include mq(sp) {
      margin-bottom: 0.75em;
    }
  }
}
.mce-ttl-01,
.is-style-mce-ttl-01 {
  font-size: calc-fz(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.mce-ttl-02,
.is-style-mce-ttl-02 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.mce-ttl-03,
.is-style-mce-ttl-03 {
  font-size: calc-fz(20);
  @include mq(sp) {
    font-size: sprem(18);
  }
}

/* 装飾付き見出し */

.txt_acc,
.txt_acc2 {
  display: grid;
  grid-template-columns: 100%;
  row-gap: rem(16);
  .txt_deco {
    font-weight: normal;
    font-family: var(--font-en);
    display: inline-block;
    width: fit-content;
    line-height: 1;
    font-size: calc-fz(20);
    padding-inline: rem(16);
    padding-block: rem(3);
    background-color: var(--clr-primary-light);
    color: var(--clr-wht);
    line-height: 1.7;
  }
}
.txt_acc2 {
  row-gap: rem(20);
}
.txt_acc2 .txt_deco {
  background-color: var(--clr-wht);
  border-radius: rem(500);
  position: relative;
  padding-inline: rem(20);
  padding-block: rem(2);
  line-height: 2.2;
  span {
    background: var(--color-gradation-gradation01);
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
  }
  &::after {
    content: "";
    position: absolute;
    width: rem(16);
    height: rem(16);
    left: 50%;
    top: calc(100% - 1px);
    translate: -50% 0;
    background: var(--clr-wht);
    clip-path: polygon(0 0, 50% 87%, 100% 0);
  }
}

.lps_sec:nth-child(even) .txt_acc2 .txt_deco {
  background-color: var(--clr-bg-01);
  &::after {
    background-color: var(--clr-bg-01);
  }
}

/* 注意書き */
.mce-txt-attention,
.is-style-txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: 0.6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "※";
  }
}

/* テーブル */
.mce-table1 th,
.mce-table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  @include mq(sp) {
    padding: 1em 0.25em;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(3));
  }
}
.mce-table1 th {
  color: var(--clr-primary-default);
  &::before {
    left: 0;
    border-bottom: 2px solid;
  }
}
.mce-table1 td::before {
  right: 0;
  border-bottom: 1px solid #d3d3d3;
}
.mce-table2 table {
  background-color: var(--clr-wht);
  @include mq(sp, min, ps) {
    th:not(:last-child) {
      border-right: 0;
    }
    td:not(:first-child) {
      border-left: 0;
    }
  }
}
.mce-table2 th {
  background-color: var(--clr-primary-default);
  color: var(--clr-wht);
}
.mce-table2 td {
  background-color: var(--clr-wht);
}

/* ボックス */
.mce-box,
.mce-box2,
.is-style-box {
  padding: rem(40);
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  *:not([class*="clr-"]):not([class*="bold"]):not([class*="mce-ttl"]) {
    color: var(--clr-text-primary);
  }
  &:not([class*="clr-"]) {
    color: var(--clr-primary-default);
  }
}
.mce-box2 {
  background: none;
  border: 1px solid var(--clr-bg-02);
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wht) .mce-box {
    background-color: var(--clr-bg-01);
  }
  .bg-wht .mce-box {
    background-color: var(--clr-bg-01);
  }
}
.lps_sec:nth-child(odd) {
  *:not(.bg-wht) .mce-box {
    background-color: var(--clr-wht);
  }
  .bg-wht .mce-box {
    background-color: var(--clr-wht);
  }
}

/* リスト */
.mce-list-circle::before,
.editor-styles-wrapper ul .is-style-list-circle::before {
  background-color: transparent;
}
.mce-list-square::before,
.editor-styles-wrapper ul .is-style-list-square::before {
  border-radius: 0;
}
ul.mce-check--list li,
.mce-list-check,
.editor-styles-wrapper ul .is-style-list-check {
  &::before,
  &::after {
    content: "";
    margin-inline-start: calc($check-start-em * -1);
  }
  &::before {
    width: $check-dot-em;
    margin-block: calc((1lh - $check-dot-em) / 2);
    background-color: var(--clr-primary-default);
    border-radius: 50%;
  }
  &::after {
    position: absolute;
    top: 0;
    left: calc(($check-dot-em - $check-em) / 2);
    float: inline-start;
    width: $check-em;
    margin-block: calc((1lh - $check-em) / 2);
    aspect-ratio: 10/7;
    border-left: 2px solid var(--clr-wht);
    border-bottom: 2px solid var(--clr-wht);
    rotate: -45deg;
  }
}
.column_list {
  column-count: 2;
  @include mq(sp) {
    column-count: 1;
  }
}

@include mq(sp) {
  /* テーブル */
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-table1.table-sp-block td::before {
    display: none;
  }
  .mce-table2.table-sp-block th,
  .mce-table2.table-sp-block td {
    border-bottom: 0;
  }
  .mce-table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
}

/* 管理画面エディタ用
*************************************************** */
#tinymce {
  padding: 24px;
}
h1.editor-post-title__input {
  padding: 32px 0;
  font-size: calc-fz(40);
  font-family: var(--font-jp);
}
.editor-styles-wrapper,
.editor-styles-wrapper * {
  font-family: var(--font-default) !important;
}
.editor-styles-wrapper {
  font-size: 16px;
}

@include mq(sp) {
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2),
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

/* コアブロック
********************************************** */
div[class*="wp-block-"] {
  margin-block: rem(24);
  @include mq(sp) {
    gap: sprem(16);
  }
  div[class*="wp-block-"] {
    margin-block: 0 !important;
  }
}
.has-text-align-left {
  text-align: left;
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-right {
  text-align: right;
}
.em {
  font-style: italic;
}
.wp-block-columns {
  display: flex;
  gap: rem(24);
  @include mq(sp) {
    gap: sprem(16);
  }
}
.wp-block-column {
  flex: 1;
  @include mq(sp) {
    flex-basis: 100% !important;
  }
  img {
    margin: 0 !important;
  }
}

/* デフォルトの機能
********************************************** */
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table.aligncenter table,
.wp-block-table.alignleft table,
.wp-block-table.alignright table {
  display: table;
  width: auto;
}
.wp-block[data-align="center"] > .wp-block-table table {
  margin: 0 auto;
}
