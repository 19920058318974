@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  padding-top: rem(25);
 
  @include mq(med) {
  padding-top: 1rem;
  &.active {
    background: var(--clr-primary-dark);
    &::after {
      opacity: 0;
    }
  }
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 60%,
        rgba(0, 212, 255, 0) 100%);
    z-index: -1;
  }

  &>.inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(24);

    @include mq(med, min, ps) {
      padding-inline: rem(64);
    }
  }
  @include mq(med) {
    height: calc( sprem($header-hgt-sp) + 0.25rem);

    &>.inner {
      padding-left: rem(30);
    }
  }
  @include mq(sp) {
    padding-top: 0;
  }
}

.template-contact-simple .header {
  position: absolute;
  padding-top: 0;
}

.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo {
  padding-top: rem(20);
  transition: var(--transit-default);

  @include mq(sp) {
    padding-top: 0;
    margin-top: auto;
  }
}
.logo--txt  {
font-size: rem(24);
transition: var(--transit-default);
}
.header.header-acrive  .logo--txt {
opacity: 0;
}
.header.active, {
 .header--logo {
  position: relative;
  z-index: 999;

 }
 .logo--txt {
  color: var(--clr-wht);
  opacity: 0;
 }
 .logo--img::before {
  opacity: 1;
 }
}

.header--logo a,
.footer--logo a {
  display: inline-block;
@include mq(sp, min, ps) {

  &:hover {
    opacity: 0.7;
  }
}
}

.header--logo a {
  display: grid;
  grid-template-columns: auto auto ;
  align-items: center;
  column-gap: rem(20);
  @include mq(sp) {
    column-gap: sprem(18);
  }
}
.logo--img {
  width: fit-content;
  position: relative;
  &::before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    background: var(--clr-wht);
    z-index: -1;
    opacity: 0;
  }
  @include mq(sp) {
  }
}

.header--logo a .img1 {
  opacity: 1;
  transition: var(--transit-default);
}

.header--logo a .img2 {
  // opacity: 0;
  transition: var(--transit-default);
}
.logo--txt {
  color: var(--clr-text-secondary);
}


.footer--logo a {

  background-color: var(--clr-wht);
}

.footer--logo {
  width: rem(102);
}

.footer--logo a {

  padding: rem(8);
}

.header--logo img {
  width: rem(66);
  @include mq(sp) {
    width: sprem(46);
  }


}

/* gnav
********************************************** */
.gnav,
.gnav--menu,
.gnav--menu li,
.gnav--link {
  @include mq(med, min, ps) {
    height: 100%;
  }
}

.gnav {
  @include mq(med, min, ps) {
    display: flex;
    column-gap: rem(48);

    height: 100%;
  }

  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100svh - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--clr-primary-dark);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.gnav--menu > .gnav--link {
  @include mq(med) {
  font-size: sprem(16);
  }
}
.gnav--link {
  line-height: 1;

  @include mq(med, min, ps) {
    @include center-flex;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}

.gnav--menu {
  @include mq(med) {
  padding-top: sprem(24);
  padding-bottom: sprem(32);
  margin-bottom: sprem(24);
  border-bottom: 1px solid var(--clr-mono-01);
  }
}

.gnav--menu .menu-item {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;

    &:not(:last-child) {
      border-bottom: none;
    }
  }
}

.gnav--link {
  padding: 0 rem(16);

  @include mq(med) {
    display: block;
    padding: sprem(12);
    &:where([href="javascript:void(0);"]):hover {
      pointer-events: all;
    }
  }

  @include mq(med, min, ps) {
    text-align: center;
  }
}

.gnav--menu>.menu-item:last-of-type .gnav--link {
  @include mq(med, min, ps) {
    padding-right: 0;
  }
}

.gnav--menu .menu-item:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    float: inline-end;
    inline-size: sprem(7);
    margin-block: calc((1lh - sprem(7)) / 2);
    aspect-ratio: 1;
    border-top: 1px solid var(--clr-wht);
    border-right: 1px solid var(--clr-wht);
    transform: rotate(45deg);
    position: absolute;
    translate: 0 calc(-50% - sprem(5));
    top: 50%;
    right: rem(16);
  }
}

.gnav--menu>.menu-item:hover>.gnav--link:not([href="javascript:void(0);"]),
.gnav--menu>.menu-item.current-menu-item>.gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-primary-light);
  }
}

.gnav--menu .menu-item:not(.menu-item-has-children)>.gnav--link .gnav_sub_toggle {
  display: none;
}

@include mq(med) {
  .gnav_sub_toggle {
  pointer-events: none!important;
  }
}

.gnav--menu>.menu-item-has-children>.gnav--link {
  @include mq(med, min, ps) {
    padding-right: rem(26);
    position: relative;

    &:hover {
      color: var(--clr-primary-light);
    }

    &::after {
      content: "";
      display: block;
      width: rem(16);
      height: rem(16);
      background-color: var(--clr-wht);
      border: 1px solid var(--clr-bg-02);
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      rotate: 45deg;
      position: absolute;
      bottom: 0;
      translate: -50% 50%;
      left: 50%;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.gnav--menu .menu-item-has-children.hover .gnav--link {
  &::after {
    opacity: 1;
  }
}

/* --- sub_nav --- */

.head_sub_nav--wrap {
  z-index: 100;

  @include mq(med, min, ps) {
    border: 1px solid var(--clr-bg-02);
    display: flex;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: var(--transit-default);
    background: var(--clr-wht);
    opacity: 0;
    pointer-events: none;
  }

  @include mq(med) {
    display: none;
  }
}

.head_sub_nav--wrap .page_ttl-jp {
  font-size: calc-fz(32);
  letter-spacing: 0.03em;
  text-align: left;
}

.head_sub_nav--wrap .page_ttl-jp em {}

.head_sub_nav--wrap .page_ttl-en {
  font-size: calc-fz(14);
  line-height: 1.6;
}
.head_sub_nav--ttl {
  @include mq(med) {
  display: none;
  }
}

.head_sub_nav--ttl .gnav--link {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  place-items: center normal;
  place-content: center;
  padding: rem(48);
  border-right: 1px solid var(--clr-bg-02);

  @include mq(sp, min, ps) {
    min-width: rem(320);
  }
}

.gnav--menu .menu-item-has-children.hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}

.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}

.head_sub_nav--wrap .head_sub_nav {
  display: flex;
  @include mq(med) {
    position: relative;
    flex-direction: column;
  }
}

.head_sub_nav--wrap .head_sub_nav li:not(:last-of-type) {
  border-right: 1px solid var(--clr-bg-02);
  @include mq(med) {
  border: none;
  }
}

.head_sub_nav--wrap .head_sub_nav .gnav--link {
  @include mq(sp, min, ps) {
    width: rem(240);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;

    &:hover {
      background: var(--clr-bg-01);
    }
  }
}

.head_sub_nav--wrap .head_sub_nav .gnav--link .img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
  @include mq(med) {
    display: none;
  }
}

.head_sub_nav--wrap .head_sub_nav .gnav--link .img img {
  transition: var(--transit-default);
}

.head_sub_nav--wrap .head_sub_nav .gnav--link:hover .img img {
  scale: 1.1;
}

.head_sub_nav--txt {
  color: var(--clr-text-secondary);
  @include mq(med) {
  color: var(--clr-wht);
  }
}

.head_sub_nav--wrap .head_sub_nav--txt {
  display: block;
  line-height: 1.6;
  padding: rem(16);
  padding-left: rem(36);
  text-align: left;
  border-top: 1px solid var(--clr-bg-02);
  position: relative;
  font-size: calc-fz(16);

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: rem(16);
    top: 50%;
    translate: 0 calc(-50% - 0.1em);
    width: rem(16);
    height: rem(16);
    background-image: url("../images/common/chevron_right.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include mq(sp) {
      display: none;
    }
  }
  @include mq(med) {
    padding-left: 0;
    border: none;
  }
}

.head_sub_nav a {
  display: block;
  padding: 0;

  @include mq(med, min, ps) {
    background-color: var(--clr-wht);
  }

  @include mq(med) {
    padding: sprem(15);
    padding-left: sprem(30);
    padding-block: 0;
  }

  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-primary-default);
    }
  }
}

.head_sub_nav--wrap .gnav_sub_toggle {
  @include mq(med, min, ps) {
    opacity: 0;
  }
  @include mq(med) {
  pointer-events: none;
  }
}

.gnav_sub_toggle {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  bottom: initial;
  right: rem(-12);
  @include sprect(56);
  transition: var(--transit-default);
  color: var(--clr-primary-default);
  cursor: pointer;

  @include mq(med, min, ps) {
    height: 100%;
    right: 0;
    width: rem(24);
    cursor: initial;
  }
   @include mq(med) {
   pointer-events: all;
   top: 0;
   translate: 0;
   height: sprem(36);
   }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: rem(11);
    height: 1px;
    border-bottom: 1px solid var(--clr-bg-01);
  }


  
  &::after {
    transition: var(--transit-default);
    transform: rotate(90deg);
  }

&.active::after {
  opacity: 0;
}
}

@include mq(med) {

  .gnav_sub_toggle.active::after {
    opacity: 0!important;
    rotate: 180deg;
  }
  
}
.gnav--menu .menu-item.hover .gnav_sub_toggle::after {
  transform: rotate(0);
  opacity: 0;
}

.header-sm .gnav_sub_toggle {

  &::before,
  &::after {
    border-bottom: 1px solid var(--clr-bg-02);
  }
}



/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }

  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    cursor: pointer;
    z-index: 9999;
  }
}

.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    top: 0.25rem;

  }
}

.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}

.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.active .gnav_btn--lines span {
  background-color: var(--clr-wht);
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-30deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(30deg);
    }
  }
}

.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }

  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }

  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}

.gnav--cta {
  text-align: center;

  @include mq(med, min, ps) {
    column-gap: rem(48);
    height: rem(64);
    margin-block: auto;
  }
  @include mq(med) {
    margin-bottom: sprem(26);
    }
}

.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}

.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
  margin-inline: auto;
}

.gnav--tel .tel {
  font-size: calc-fz(20);
  color: var(--clr-primary-default);
  @include mq(sp) {
  color: var(--clr-wht);
  }
}

.gnav--tel .num {
  font-size: calc-fz(24);
}

.gnav--tel .hours {
  font-size: calc-fz(14);
  color: var(--clr-bg-02);
}

.gnav--contact a {
  padding-block: rem(20);
  border-radius: rem(500);
  padding-right: rem(48);
  padding-left: rem(48);
  line-height: 1;
  place-items: center;

  &::before {
    display: none;
  }

  @include mq(med) {
    padding: sprem(12) sprem(16);
    margin-left: auto;
    margin-right: auto;
    font-size: sprem(16);
  }
}

.gnav--contact .icon {
  width: rem(17);
  height: rem(25);
}

/* header 01
********************************************** */
.header-01 {
  @include mq(med, min, ps) {
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);

    .gnav--menu>li:hover > .gnav--link:not([href="javascript:void(0);"]),
      .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-primary-default);
      background-color: var(--clr-bg-01);
    }
  }

  >.inner {
    padding-inline: rem(24);
  }

  .head_sub_nav--wrap {
    @include mq(sp) {
      padding-inline: sprem(14);
      background-color: var(--clr-wht);
    }
  }

  .head_sub_nav li {
    @include mq(med, min, ps) {
      margin-block: rem(8);
    }
  }

  .head_sub_nav a {
    line-height: 1.5;

    @include mq(med, min, ps) {
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
  }

  .gnav--cta {
    display: flex;
    align-items: center;
  }

  .gnav--contact {
    height: fit-content;

    a {
      padding: rem(8) rem(24);
      border-radius: rem(32);
    }
  }
}

/* header 02
********************************************** */
.header-02 {
  @include mq(med, min, ps) {
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);

    .gnav--menu>li:hover > .gnav--link:not([href="javascript:void(0);"]),
      .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-primary-default);
      background-color: var(--clr-bg-01);
    }
  }

  .head_sub_nav--wrap {
    @include mq(sp) {
      padding-inline: sprem(14);
      background-color: var(--clr-wht);
    }
  }

  .head_sub_nav li {
    @include mq(med, min, ps) {
      margin-block: rem(8);
    }
  }

  .head_sub_nav a {
    line-height: 1.5;

    @include mq(med, min, ps) {
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
  }

  .gnav--tel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--clr-mono-01);

    a {
      background: none;
    }
  }

  .gnav--contact {
    margin-left: 0;
  }
}

/* header 03
********************************************** */
.header-03 {
  @include mq(med, min, ps) {
    padding-right: rem(32);
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);

    .gnav--menu>li:hover > .gnav--link:not([href="javascript:void(0);"]),
      .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-primary-default);
      background-color: var(--clr-bg-01);
    }
  }

  .header--logo a {
    @include rect(200);
    position: absolute;
    top: 0;
    left: 0;
    @include center-flex;
    background: var(--clr-wht);
    border-radius: 0px 0px rem(32) 0px;
    box-shadow: 0px 0px rem(24) 0px rgba(0, 0, 0, 0.05);
  }

  &.header-sm .header--logo a {
    @include rect(180);
  }

  .head_sub_nav--wrap {
    left: 50%;
    translate: -50% 0;
    min-width: rem(248);
  }

  .head_sub_nav {
    position: relative;
    min-width: rem(248);
    background-color: var(--clr-wht);

    @include mq(med, min, ps) {
      padding: rem(20);
      margin-top: rem(20);
      border-radius: rem(8);
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }

    @include mq(sp) {
      padding: sprem(10) sprem(16);
    }

    @include mq(med, min, ps) {
      &::before {
        content: "";
        position: absolute;
        top: rem(-12);
        left: calc(50% - rem(24) / 2);
        width: rem(24);
        height: rem(12);
        background-color: var(--clr-wht);
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }
  }

  .head_sub_nav a {
    position: relative;
    padding: rem(10);
    text-align: left;
    line-height: 1.5;

    @include mq(sp) {
      padding: 1em 1.5em;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      @include rect(8);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
  }

  .gnav--contact a {
    gap: rem(8);

    @include mq(med, min, ps) {
      height: 130%;
      border-radius: 0 0 rem(24) rem(24);
      flex-direction: column;
    }
  }



  .icon-mail {
    @include mq(med, min, ps) {
      width: rem(32);
    }
  }

  .gnav--contact a::before {
    display: none;
  }
}

@include mq(med) {
  .gnav-privacy {
    display: flex;
    justify-content: center;
    column-gap: sprem(16);
    a {
      color: var(--clr-wht);
      font-size: sprem(12);
    }
  }
  .gnav-copy {
    display:  block;
    text-align: center;
    color: var(--clr-text-light);
    font-size: sprem(12);
    line-height: 1.6;
    opacity: 0.5;
  }
}
