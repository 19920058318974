@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */


@keyframes hscroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll_line {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-100%);
  }
}


// @keyframes fadeIn {
//   from {
//     opacity: 0;
//     translate: 0 -10%;
//   }
//   to {
//     opacity: 1;
//     translate: 0 0;
//   }
// }

@keyframes poyoyon {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0; /* 完全に透明 */
  }
  20% {
    transform: translateY(-10px) scale(1.1);
    opacity: 0.5; /* 半透明 */
  }
  40% {
    transform: translateY(0) scale(0.95);
    opacity: 0.8; /* 少し透明 */
  }
  60% {
    transform: translateY(-5px) scale(1.05);
    opacity: 1; /* 完全に表示 */
  }
  80% {
    opacity: 1; 
    transform: translateY(0) scale(0.98);
  }
  100% {
    opacity: 1; 
    transform: translateY(0) scale(1);
  }
}