@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: rem(16) 0;
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.breadcrumbs a {
  &:hover {
    color: var(--clr-primary-default);
  }
}

.breadcrumbs span span {
  position: relative;
  padding-right: rem(22);
}
.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  translate:  0 calc(-50% - .1em);
    width: rem(16);
    height: rem(16);
    background-image: url('../images/common/chevron_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}
.breadcrumb_last {
  color: var(--clr-primary-light);
}
