@use "../abstracts" as *;

/* bg
********************************************** */
// 背景色を出力 
// 出力例）.bg-primary-default
@each $key, $color in $colors {
  $key-suffix: str-slice($key, 5); // 'clr-'を削除（懸念点）
  .bg-#{$key-suffix} {
    background-color: var(--#{$key});
  }
}

// ↑だとbg系が難しい…ベタで書いたがいい案あれば修正頼む
.bg-01 {
  background-color: var(--clr-bg-01);
}
.bg-02 {
  background-color: var(--clr-bg-02);
}
.bg-03 {
  background-color: rgba(#fff,  0.8);
}