@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: rem(32);
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: rem(24);
  background-color: var(--clr-bg-01);

  p.ez-toc-title {
    color: var(--clr-text-primary);
    font-weight: bold;
    font-size: calc-fz(20);
  }

  a,
  a:hover,
  a:visited {
    color: var(--color-primary-default) !important;
  }
}

.ez-toc-title-container {
  margin-bottom: rem(10);
}

#ez-toc-container.counter-hierarchy {
  ul {
    font-size: calc-fz(17);
  }

  ul li {
    margin-top: rem(8);
  }

  ul ul {
    margin-left: rem(16);
  }

  ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);

    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}

#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  @include center-flex;
  height: rem(48);
  padding: 0.5em;
  line-height: 1;
  font-size: calc-fz(12);
  font-weight: bold;
  border: 1px solid var(--clr-mono-02);

  @include mq(sp) {
    height: rem(40);
  }
}

.share--list li {
  &.x a {
    color: #000;
  }

  &.facebook a {
    color: #2477f2;
  }

  &.line a {
    color: #27c754;
  }

  &.pocket a {
    color: #ef4056;
  }

  &.linkedin a {
    color: #2867b2;
  }

  &.hatena a {
    color: #29a4de;
  }
}

.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc-clamp(4, $wid-var);
  white-space: nowrap;
}

.wp-pagenavi>* {
  @include center-flex;
}

.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi .current {
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border-color: var(--clr-primary-default);
}

.wp-pagenavi a {
  position: relative;
  padding: 0 1em;
  height: calc-clamp(40, $wid-var);
  color: var(--clr-primary-default);
  border: 1px solid var(--clr-primary-default);
  background-color: var(--clr-wht);

  @include mq(sp) {
    height: sprem(36);
  }

  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
}

.archive .wp-pagenavi>*:not(.pages) {
  width: calc-clamp(40, $wid-var);
  aspect-ratio: 1;

  @include mq(sp) {
    width: sprem(36);
  }
}

.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  aspect-ratio: 1;
}

.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1;
}

.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: rem(-6);
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: rem(-6);
}

/* interview
**************************************** */
.single-interview {
  padding-top: rem($header-hgt + 10);
  @include mq(sp) {
    padding-top: sprem($header-hgt-sp);
  }
}

.header .single-interview {
  background: var(--clr-bg-01);
}

.single-interview .breadcrumbs {
  padding-block: rem(16);
  background: var(--clr-wht);
  border: 1px solid var(--clr-bg-02);
  border-width: 1px 0;
}

.single-interview .breadcrumbs>div {
  width: 90%;
  max-width: rem($inner);
  margin-inline: auto;
}

.single-interview .header--logo {
  margin-top: auto;
  padding-top: 0;
  translate: 0 calc(rem(10) + 1px);
  @include mq(sp) {
    translate: 0;
  }
}

/* persons
*************************************** */
.single-persons {
  background-color: #fff;
}

/* --- persons--ttl --- */
.persons--ttl {
  overflow: hidden;
  min-width: 600px;
  padding-top: 88px;
  padding-bottom: 72px;
  margin-bottom: 0;

  @include mq(sp) {
    height: auto;
    min-width: 0;
    padding-top: 0;
    padding-bottom: 36px;
    display: grid;
    grid-template-columns: 100%;
    row-gap: sprem(40);
  }
}
.persons--ttl .grid_filter {
  height: 100%;
}

.persons--ttl .imgarea {
  position: relative;
  width: vw(1428);
  height: rem(800);
  margin-left: auto;

  @include mq(sp) {
    width: 100%;
    height: auto;
  }
}

.persons--ttl .txtarea {
  @include center-flex;
  position: absolute;
  @include mq(sp, min, ps) {
    top: 0;
    left: vw(190);
    bottom: 0;
  }
  color: #fff;

  @include mq(sp) {
    position: relative;
  }
}

.txtara_inner_wrap {
  display: flex;
  flex-direction: column;
  @include mq(sp) {

    padding-inline: 10%;
  }
}

.persons--ttl .before_num {
  font-size: calc-fz(200);
  color: var(--clr-wht);
  opacity: 0.2;
  position: absolute;
  left: -0.5em;
  bottom: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: normal;
  @include mq(sp) {
    font-size: sprem(160);
  }
}

.persons--ttl .num {
  margin-bottom: rem(40);
  font-size: calc-fz(20);
  line-height: 1;
  letter-spacing: 0.1em;

  @include mq(sp) {
    margin-bottom: rem(-12);
    font-size: sprem(16);
    transform: translateX(-0.25em) rotate(-5deg);
  }
}

.persons--ttl .year {
  display: inline-block;
  border: 1px solid var(--clr-wht);
  padding: rem(4) rem(8);
  line-height: 1;
  margin-left: rem(32);
}

.persons--ttl .ttl {
  margin-bottom: rem(40);
  font-size: calc-fz(56);
  line-height: 1.4;
  letter-spacing: 0.1em;

  @include mq(sp) {
    margin-bottom: rem(16);
    font-size: calc-fz(24);
  }
}

.persons--ttl .ttl-02 {
  margin-top: -0.25rem;
  margin-bottom: 1.5rem;
  color: var(--clr-wht);

  @include mq(sp) {
    margin-top: -0.125rem;
    margin-bottom: 0.75rem;
  }
}

// member_interview
.member_interview_sec {
  @include mq(sp, min, ps) {
    display: flex;
    align-items: flex-start;
  }

  &:nth-child(2n) .member_interview_sec--img {
    order: -1;
  }
}

.member_interview_sec--txtarea,
.member_interview_sec--img {
  @include mq(sp, min, ps) {
    width: 50%;
  }
}

.member_interview_sec--txtarea {
  @include mq(sp, min, ps) {
    padding-inline: rem(160);
  }
}

.member_interview_sec--img {
  position: sticky;
  top: 0;
  height: 100vh;

  @include mq(sp) {
    height: 100vw;
  }
}

.member_interview_item:not(:last-child) {
  margin-bottom: rem(64);
}

.member_interview_item--ttl {
  padding: 0.5em 0.5em;
  color: var(--clr-wht);

  @include mq(sp) {
    padding: 0.5em 1em;
  }
}

.member_interview_item--list li:not(:last-child) {
  margin-bottom: rem(64);

  @include mq(sp) {
    margin-bottom: rem(32);
  }
}

.member_interview_item-subttl {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: baseline;
  @include mq(sp) {
   
    row-gap: sprem(8);
  }
}

.member_interview_item--txtarea {
  background: var(--clr-wht);
  border-radius: rem(8);
  padding: rem(32) rem(32);
  filter: drop-shadow(0px 0px 24px rgba(141, 146, 158, 0.2));
  position: relative;
  @include mq(sp) {
    border-radius: 0;
  }

  &::after {
    content: "";
    width: rem(15);
    height: rem(22);
    left: calc(100% - 1px);
    top: 50%;
    translate: 0 -50%;
    position: absolute;
    background: var(--clr-wht);
    clip-path: polygon(0 0, 0% 100%, 86% 50%);
    @include mq(sp) {
     display: none;
    }
  }
}

.member_interview_sec:nth-child(2n) .member_interview_item--txtarea::after {
  left: initial;
  right: calc(100% - 1px);
  clip-path: polygon(100% 0, 14% 50%, 100% 100%);
}

.member_interview_back {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;

  .deco,
  .deco2,
  .deco3 {
    grid-row: 1;
  }
}

.member_interview_back .deco {
  grid-column: 1;
  display: flex;

  img {
    margin-top: auto;
  }

  &2 {
    grid-column: 2;
    margin-left: auto;
  }

  &3 {
    margin-top: auto;
    grid-column: 1;
    margin-left: rem(80);
    margin-bottom: rem(80);
  }
}

.member_schedule .flow_item {
  display: grid;
  grid-template-columns: rem(150) 1fr;
  column-gap: rem(25);
  line-height: 1;
}

.member_schedule {

  .lps_parts--flow .ttl,
  .lps_parts--flow:not(.flow-num) .ttl span {
    line-height: 1 !important;
  }

  .lps_parts--flow:not(.flow-num) .ttl span::before,
  .lps_parts--flow:not(.flow-num) .ttl::before {
    position: absolute;
    inline-size: rem(16);
    block-size: rem(16);
    left: calc(1.49rem);
    margin-block: calc((1lh - rem(32)) / 2);
    font-size: calc-fz(32);
  }

  .lps_parts--flow:not(.flow-num) .ttl span::before {
    translate: 0 0;
  }

  .lps_parts--flow {
    .flow_item:not(:last-child) .ttl::after {
      margin-top: calc(0.5rem + (1lh - 0.5rem) / 2) !important;
      margin-bottom: calc((1lh - 1rem) / -2) !important;
      @include mq(sp) {
        left: -1.6rem !important;
      }
    }
  }
}

.member_interview_sec--txtarea {
  @include mq(sp) {
    padding-inline: 5%;
  }
}