@use "../abstracts" as *;

/* default
**************************************** */
.section_pdg {
  padding-block: rem(120);
  @include mq(sp) {
    padding-block: sprem(60);
  }
}
.section_pdg.not_padding {  
  padding-block: 0;
  @include mq(sp) {
  padding-bottom: sprem(40);
  }
}
.section_pdg-top {
  padding-top: rem(120);
  @include mq(sp) {
    padding-top: sprem(60);
  }
}
.section_pdg-btm {
  padding-bottom: rem(120);
  @include mq(sp) {
    padding-bottom: sprem(60);
  }
}
.section_pdg:has(.pin_wrap) {
  padding-bottom: 0;
}

/* sm
**************************************** */
.section_pdg-sm {
  padding-block: rem(80);
  @include mq(sp) {
    padding-block: sprem(40);
  }
}
.section_pdg-sm-top {
  padding-top: rem(80);
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.section_pdg-sm-btm {
  padding-bottom: rem(80);
  @include mq(sp) {
    padding-bottom: sprem(40);
  }
}


/* md
**************************************** */
.section_pdg-md {
  padding-block: rem(120);
  @include mq(sp) {
    padding-block: sprem(40);
  }
}
.section_pdg-md-top {
  padding-top: rem(120);
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.section_pdg-md-btm {
  padding-bottom: rem(120);
  @include mq(sp) {
    padding-bottom: sprem(40);
  }
}

