@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* txt_slide
********************************************** */
  .txt_slidewrap {
    position: absolute;
    bottom: -0.2em;
    left: 0;
    width: fit-content;
    white-space: nowrap;
    overflow: clip;
    width: 100%;
    font-size: calc-fz(200);
    line-height: 1;

    @include mq(sp) {
      display: flex;
      white-space: nowrap;
      height: fit-content;
      font-size: sprem(80);
      line-height: normal;
    }
  }

  .home_txt_slidewrap {
    bottom: -0.225em;
  }

  .txt_slide--item {
    line-height: 1;
    display: inline-block;
    white-space: nowrap;
    font-family: var(--font-en);
    font-weight: 700;
    animation: hscroll 64s linear infinite 0.5s both;
    color: var(--clr-bg-01);

    @include mq(sp) {
      animation: hscroll 28s linear infinite 0.5s both;
      line-height: normal;
    }
  }

  scroll-padding-top: rem($header-hgt-fx);

  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: calc-fz($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }

  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
    scroll-padding-top: rem($header-hgt-sp);
  }

  &.fixed {
    overflow-y: hidden;
  }
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  word-break: break-word;

  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: max(14px, calc-clamp($fz-pc, 1080));

    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }

  @include mq(med) {
    padding-top: calc(sprem($header-hgt-sp) + 0.25rem);
  }

  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}

* {
  letter-spacing: var(--ltr-space-default);
}

ul,
ol {
  list-style: none;
}

small {
  font-size: calc-fz(14);

  @include mq(sp) {
    font-size: sprem(13);
  }
}

a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}

a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: none;
}

a:focus {
  text-decoration: none !important;
}

a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;

  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr);
}

svg {
  transition: var(--transit-default);

  &[preserveAspectRatio="none"] {
    height: auto;
  }
}

.pin_wrap {
  height: 100vh;
  position: relative;
  color: #fff;
  background: var(--clr-wht);
  @include mq(sp) {
    height: fit-content;
  }
}

.section__pin {
  max-height: calc(100svh - 80px);
  width: 100%;
  top: 0;
  @include mq(sp) {
    max-height: fit-content;
    .lps_parts--slide .slick-list {
      margin-bottom: 0;
    }
  }
}

.ttl_link_cont {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "ttl link"
    "cont cont";

  @include mq(med, min, ps) {
    place-items: center normal;
  }

  @include mq(sp) {
    grid-template-columns: 100%;
    grid-template-areas:
      "ttl "
      "cont "
      "link";
  }
}

.ttl_link_cont--ttl {
  grid-area: ttl;
}

.ttl_link_cont--link {
  grid-area: link;
}

.ttl_link_cont--cont {
  grid-area: cont;
}

.grad_txt {
  background: var(--color-gradation-gradation01);
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

.grad_txt2 {
  background: var(--color-gradation-gradation02);
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

.grad_txt3 {
  background: var(--color-gradation-gradation03);
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

//gridflame

.grid_filter {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  // padding: rem(16);
  display: grid;
  grid-template-columns: calc($cell * 3 + rem(16)) repeat(8, calc($cell * 4 - rem(4))) calc($cell * 3 + rem(16)); //横
  grid-template-rows: repeat(6, calc($cell_row * 3));

  z-index: 100;
  mix-blend-mode: overlay;
  opacity: 0.3;
}

.scale_grid {
  pointer-events: none;
}

.grid_filter--cell {
  width: 100%;
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    left: 0;
    display: block;
    background: #fff;
    position: absolute;
  }

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    display: block;
    background: #fff;
    position: absolute;
  }
  @include mq(sp) {
    &:nth-of-type(2n) {
      &::after {
        display: none;
      }
    }
  }
}

.grid_filter--cell.cell {
  &51,
  &52,
  &53,
  &54,
  &55,
  &56,
  &57,
  &58,
  &59,
  &60 {
    &::before {
      display: none;
    }
  }
}
$sp_width:0.25rem;

.grid_flame_wrap {
  pointer-events: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  padding: calc(rem(16) - 1px);
  border: 1px solid var(--clr-bg-01);
  @include mq(sp) {
    height: 100vh;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: rem(18) solid var(--clr-bg-01);
    z-index: -1;
    @include mq(sp) {
      border: calc($sp_width * 2 + 0.125rem) solid var(--clr-bg-01);
    }
  }
  @include mq(med) {
    padding: calc($sp_width - 1px);
  }

  @include mq(sp) {
  }
}

.grid_frame {
  width: 100%;
  height: rem(16);
  padding: 0 rem(16);
  display: grid;
  grid-template-columns: repeat(38, $cell) 1fr; //横
  position: absolute;
  top: rem(16);
  left: 0;
  z-index: 100;
  @include mq(med) {
    height: $sp_width;
    padding: 0 $sp_width;
    top: $sp_width;
  }
  &.bottom {
    top: initial;
    bottom: rem(16);
    @include mq(med) {
      bottom: $sp_width;
    }
  }
}

.grid_frame--cell {
  width: 100%;
  background: var(--clr-bg-01);
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    left: 0;
    display: block;
    background: var(--clr-bg-02);
    position: absolute;
  }
  @include mq(med) {
    &:nth-of-type(2n + 1)::after,
    &:first-of-type,
    &:last-of-type,
    &:nth-of-type(2) {
    opacity: 0;
    }
  }

  &.cell1::after {
    display: none;
  }
}

.grid_frame--cell.cell20 {
  &::after {
    height: 300%;
    top: rem(-16);
    @include mq(sp) {
      top: calc(-1 * $sp_width);
    }
  }
}

.grid_frame_row {
  width: rem(16);
  height: 100vh;
  padding: rem(16) 0;
  display: grid;
  grid-template-rows: repeat(18, $cell_row); //縦
  position: absolute;
  top: 0;
  left: rem(16);
  z-index: 100;
  @include mq(med) {
    width: $sp_width;
    padding: $sp_width 0;
    left: $sp_width;
  }
  &.right {
    left: initial;
    right: rem(16);
    @include mq(med) {
      right: $sp_width;
    }
  }
}

.grid_frame_row--cell {
  width: 100%;
  background: var(--clr-bg-01);
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    display: block;
    background: var(--clr-bg-02);
    position: absolute;
  }

  &:last-of-type::after {
    bottom: 1px;
  }
}

.grid_frame_row--cell.cell10 {
  &::after {
    width: 300%;
    left: rem(-16);
    @include mq(med) {
       left: calc(-1 * $sp_width)
    }
  }
}

.grid_frame_inner {
  width: 100%;
  height: 100%;
  border: 1px solid var(--clr-bg-02);
  padding: calc(rem(16));
  @include mq(med) {
    padding: $sp_width;
  }
}

.grid_frame_inner_inner {
  width: 100%;
  height: 100%;
  border: 1px solid var(--clr-bg-02);
}

.primary_txt {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}

.pagetop {
  position: fixed;
  right: rem(24);
  bottom: rem(24);
  width: rem(64);
  height: rem(64);
  background: var(--clr-primary-dark);
  border-radius: 100%;
  z-index: 10000;
  cursor: pointer;
  transition: var(--transit-default);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  rotate: -90deg;
  &.active {
    opacity: 1;
  }
  @include mq(sp, min, ps) {
    &:hover {
      opacity: 0.8;
    }
  }
}
