@use "../abstracts" as *;

/* crosstalk
********************************************** */
.talk--contents .icon-area {
  width: rem(96);
  aspect-ratio: 1 /1;
  @include mq(sp) {
    width: 15vw;
  }
}
.talk--contents .icon-area .img {
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
}
.talk--contents .serif {
  position: relative;
  width: calc(100% - rem(150));
  padding: rem(32) rem(40);
  background-color: var(--clr-wht);
  border-radius: rem(8);
  line-height: 1.6;
  @include mq(sp) {
    width: 67vw;
    padding: vw(60) vw(80);
    border-radius: rem(8);
    font-size: calc-fz(14);
  }

  &::before {
    content: "";
    position: absolute;
    left: rem(-18);
    top: 50%;
    display: block;
    width: rem(22);
    height: rem(22);
    translate: 0 -50%;
    background-image: url("../images/common/fukidashi.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include mq(sp) {
      top: 0;
      left: rem(-12);
      bottom: 0;
      margin: auto;
      width: rem(22);
      height: rem(22);
    }
  }
}
.talk--contents .flex-reverse .serif::before {
  left: auto;
  right: rem(-18);
  transform: scale(-1, 1);
  @include mq(sp) {
    right: rem(-12);
  }
}

.lps_sec:nth-child(even) {
  .talk--contents .serif {
    background-color: var(--clr-bg-01);
     &::before {
         background-image: url("../images/common/fukidashi_2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
     }
  }
}