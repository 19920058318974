@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.page_ttl--bg {
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  grid-area: ttl_bg;
  overflow: hidden;
  margin-top: auto;
  height: rem(485);
  margin-top: rem(32);
  @include mq(sp) {
  margin-top: 0;
  height: sprem(204);
  }
}
.page_ttl--bg .grid_filter {
  position: absolute;
  padding: rem(16) rem(0);
  // grid-template-columns: calc($cell * 3 + rem(16)) repeat(8, calc($cell * 4 - rem(8))) calc($cell * 3 + rem(16));
  grid-template-columns: repeat(8, calc(100% / 38 * 8));
  width: 100%;
  left: initial;
  right: 0;
  padding-right: 1rem;
}
.page_ttl--txt {
  grid-area: ttl_txt;
  padding-top: rem(212);
  padding-left: calc(50vw - min(90%, rem($inner_half)));
  @include mq(sp) {
    padding-top: 0;
    padding-block: sprem(40);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.page_ttl-jp {
  font-size: calc-fz(58);
  letter-spacing: 0.03em;
  color: var(--clr-text-secondary);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.page_ttl-en {
  display: block;
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(14);
  }
}

.page_ttl--bread {
  grid-area: ttl_bread;
  //2pxはボーダーの差分
  height: calc((100vh - rem(32)) / 18);
  border: 1px solid var(--clr-bg-02);
  border-width: 1px 0;
  padding-left: calc(50vw - min(90%, rem($inner_half)));
  background: var(--clr-wht);
  display: grid;
  place-content: center normal;
  @include mq(sp) {
    padding-inline: 5%;
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: rem(32);
  .page_ttl--txt {
    padding-top: rem(140);
    padding-bottom: rem(100);
  }
  @include mq(sp) {
    padding-top: sprem(24);
  }
  .breadcrumbs {
    text-align: left;
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  background-color: var(--clr-bg-01);
  // height: calc((100vh - rem(32)) / 18 * 9 + rem(16));
  // height: rem(540);
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "ttl_txt ttl_bg"
    "ttl_bread ttl_bread";
    @include mq(sp) {
      height: fit-content;
      grid-template-columns: 100%;
      grid-template-areas:
      "ttl_txt"
      "ttl_bread"
      "ttl_bg";
      grid-template-rows: 1fr auto auto;

    }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @include mq(sp) {
    .breadcrumbs {
      padding-inline: 0 5%;
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
  .breadcrumbs span span:not(:last-child)::after {
  }
}

/* --- page_ttl_simple --- */


.page_ttl_simple {
  padding-top: rem(120);
  background: var(--clr-bg-01);

}

.page-sitemap,
.page-simple {
  padding-top: rem($header-hgt + 10);
  @include mq(sp) {
    padding-top: sprem($header-hgt-sp);
  }
  .header{
  @include mq(sp, min, ps) {
    background: var(--clr-bg-01);
  }
    height:  rem($header-hgt + 10);
    @include mq(sp) {
      height: sprem($header-hgt-sp);
    }
  }
  
  .header--logo {
    margin-top: auto;
    padding-top: 0;
    translate: 0 1px;
    @include mq(sp) {
      padding-top: 0;
      margin-top: auto;
    }
  }
  
}


/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;

  color: var(--clr-text-secondary);
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
  span {
    line-height: inherit;
    letter-spacing: inherit;
  }
}
.ttl-01 {
  color: var(--clr-primary-dark);
  font-size: calc-fz(56);

  padding-bottom: rem(44);
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: rem(100);
    height: rem(20);
    background-image: url("../images/common/title-deco_scale.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    bottom: 0;
    left: 0;
  }
  &.txt-ctr::after {
    left: 50%;
    translate: -50% 0;
  }
  @include mq(sp) {
    font-size: sprem(28);
  }

  &.font-en {
    font-size: calc-fz(104);
    .maintitle {
      line-height: 0.88;
      margin-bottom: rem(32);
      background: var(--color-gradation-gradation01);
      -webkit-background-clip: text;
      color: rgba(0, 0, 0, 0);
      letter-spacing: normal;
      line-height: normal;
      @include mq(sp) {
        margin-bottom: sprem(12);
      }
    }
    @include mq(sp) {
      font-size: sprem(32);
    }
  }
  .maintitle {
    position: relative;
    display: block;
    line-height: 1.4;

  }
  .subtitle {
    @include mq(sp) {
      margin-top: sprem(8);
    }
  }
  &.font-en {
    .subtitle {
      font-size: calc-fz(24);
      color: var(--clr-text-secondary);
    }
  }
  &.clr-wht .subtitle {
    color: var(--clr-wht);
  }
}

.ttl-02 {
  font-size: calc-fz(48);

  span {
    display: inline-block;
    background: var(--color-gradation-gradation01);
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
    width: fit-content;
  }
  @include mq(sp) {
    font-size: sprem(30);
  }
  &.font-en {
    font-size: calc-fz(48);
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
  &.clr-wht {
    span {
      color: var(--clr-wht);
    }
  }
}

.ttl-03 {
  font-size: calc-fz(32);
  @include mq(sp) {
    font-size: sprem(22);
  }
  &.font-en {
    font-size: calc-fz(32);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }

}
.ttl-04 {
  font-size: calc-fz(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &.font-en {
    font-size: calc-fz(32);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-05 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
  &.font-en {
    font-size: calc-fz(24);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}
.ttl-06 {
  font-size: calc-fz(20);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}

.ttl01_subcont {
  @include mq(sp) {
    padding-inline: 5%;
    margin-bottom: sprem(60);
  }
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: 0.03em !important;
}
.subtitle {
  display: block;
  color: var(--clr-primary-default);
  font-size: calc-fz(18);
  padding-inline: rem(24);
  width: fit-content;
  margin-inline: auto;
  &:not(.txt-ctr) {
    margin-inline: 0;
  }
  position: relative;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: rem(16);
    border-bottom: 2px solid var(--clr-secondary-default);
    position: absolute;
    top: 50%;
    translate: 0 -2px;
  }
  &::before {
    left: 0;
  }
  &::after {
    display: none;
    right: 0;
    @include mq(sp) {
      display: block;
      right: 0;
    }
  }

  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(8, 16, em);
  }
}
.ttl-01.txt-ctr .subtitle::after {
  display: block;
}
.font-en .subtitle::after {
  display: none;
  @include mq(sp) {
   display: block;
  }
}
.subtitle-sm {
  display: block;
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: calc-fz(14);
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: calc-fz(152);
  @include mq(sp) {
    font-size: sprem(56);
  }
}
